import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	fetchOneTimeTaskDetails,
	oneTimeTaskApi,
} from "../backend/backendApis";
import { oneTimeTasks } from "../data/taskCollections";
import ConfirmationWindow from "./ConfirmationWindow";
import CustomCheckbox from "./CustomCheckbox";
import {
	buttonStyle,
	containerStyle,
	errorStyleText,
	imgContainer,
	imgStyle,
} from "./formStyle";
import InfoButton from "./InfoButton";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import HeaderBar from "./HeaderBar";
import HttpService from "../backend/requestApis";
import Loader from "./Loader";
import { getUser } from "../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import BackArrow from "./BackArrow";
import WORKslogans from "./WORKslogans";

const OneTimeTaskReportForm = () => {
	// const [checkboxStates, setCheckboxStates] = useState(
	// 	oneTimeTasks.reduce((acc, task) => ({ ...acc, [task.taskName]: false }), {})
	// );

	const navigate = useNavigate();

	const [contactNumber, setContactNumber] = useState("");
	const [gender, setGender] = useState(null);

	const [checkboxObject, setChecboxObject] = useState({});
	const [checkboxStates, setCheckboxStates] = useState({});

	const [isButtonSubmit, setIsButtonSubmit] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState({});
	const [apiError, setApiError] = useState(null);

	useEffect(() => {
		async function fetchUser() {
			try {
				setIsLoading(true);
				let contactNumber = await localStorage.getItem("contact");
				setContactNumber(contactNumber);
				// setIsLoading(false)
			} catch (err) {
				setIsLoading(false);
				navigate("/signUp");
			}
		}
		setApiError(null);
		fetchUser();
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (contactNumber) {
				try {
					setIsLoading(true);
					let token = await localStorage.getItem("token");
					if (token) {
						let options = {
							url: fetchOneTimeTaskDetails,
							method: "GET",
							params: {
								contactNumber: contactNumber,
							},
							headers: {
								Authorization: `Bearer ${token}`,
							},
						};
						setIsLoading(true);
						HttpService(options)
							.then((res) => {
								setChecboxObject(res.data.data);
								setGender(res.data.data.gender);
								setIsLoading(false);
							})
							.catch((err) => {
								console.log("Error in fetching daily tasks.", err);
								if (err.status === 409) {
									setApiError(err.response.data.description);
								} else {
									setApiError(
										"Error in fetching your task status. Please contact Support."
									);
								}
								setIsLoading(false);
							});
					} else {
						setIsLoading(false);
						navigate("/signUp");
					}
				} catch (err) {
					setApiError(
						"Error in fetching your task status. Please contact Support."
					);
					setIsLoading(false);
				}
			}
		}
		fetchData();
	}, [contactNumber]);

	useEffect(() => {
		if (Object.keys(checkboxObject).length > 0) {
			setIsLoading(true);
			setCheckboxStates(() =>
				oneTimeTasks.reduce(
					(acc, task) => ({
						...acc,
						[task.taskName]: checkboxObject[task.taskName] || false,
					}),
					{}
				)
			);
			setIsLoading(false);
		}
	}, [checkboxObject]);

	const getDistricts = () => {
		if (checkboxObject.gender === "Female") {
			return {
				firstDistrict: checkboxObject.selectedDistrict,
				secondDistrict: checkboxObject.secondSelectedDistrict,
			};
		} else {
			return null;
		}
	};

	const districts = getDistricts();

	function closeWindow() {
		setIsOpenWindow(false);
		setIsButtonSubmit(false);
	}

	const handleCheckboxChange = (taskName) => {
		setCheckboxStates((prevState) => ({
			...prevState,
			[taskName]: !prevState[taskName],
		}));
	};

	// Function to get records of checked checkboxes
	const getCheckedTasks = () => {
		return Object.keys(checkboxStates).filter(
			(taskName) => checkboxStates[taskName] === true
		);
	};

	const isAllTasksCompleted =
		gender === "Male"
			? getCheckedTasks().length === oneTimeTasks.length - 2
			: getCheckedTasks().length === oneTimeTasks.length;

	async function handleSubmit() {
		setApiError(null);
		setIsOpenWindow(true);
		setIsButtonSubmit(true);

		if (getCheckedTasks().length === 0) return;

		let token = await localStorage.getItem("token");
		if (token) {
			checkboxStates.contactNumber = contactNumber;
			checkboxStates.gender = gender;
			let apiOptions = {
				url: oneTimeTaskApi,
				method: "POST",
				payload: checkboxStates,
				headers: {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			};

			setOptions(apiOptions);
		} else {
			setIsLoading(false);
			navigate("/signUp");
		}
	}

	if (isLoading) return <Loader />;

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
				}}
			>
				<HeaderBar isReportForm={true} />
				<div style={containerStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<WORKslogans />
					</div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<BackArrow />
					<h3>One Time Task</h3>
					<Paper
						style={{
							maxHeight: "50vh",
							overflowY: "auto",
							padding: "0rem",
							scrollbarWidth: "none",
						}}
					>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.2rem",
								padding: "1rem 0.5rem",
							}}
						>
							{isFormSubmitted ? (
								<ReportFormSubmitMessage />
							) : !apiError ? (
								<div>
									{oneTimeTasks.map((task) => (
										<CustomCheckbox
											taskId={task.taskId}
											key={task.taskId}
											label={task.taskTitle}
											description={task.taskDescription}
											isCheckboxChecked={checkboxStates[task.taskName] || false}
											setIsCheckboxChecked={() =>
												handleCheckboxChange(task.taskName)
											}
											districts={districts}
											taskName={task.taskName}
										/>
									))}
									<div
										style={{
											textAlign: "center",
										}}
									>
										<Button
											style={{
												...buttonStyle,
												fontSize: "0.8rem",
											}}
											onClick={handleSubmit}
										>
											Submit
										</Button>
										{isButtonSubmit && (
											<ConfirmationWindow
												windowStatus={isOpenWindow}
												updateWindowStatus={closeWindow}
												isAllTasksCompleted={isAllTasksCompleted}
												isNoTaskChecked={getCheckedTasks().length === 0}
												setIsFormSubmitted={setIsFormSubmitted}
												options={options}
												setIsLoading={setIsLoading}
												setApiError={(err) => {
													setApiError(err);
												}}
											/>
										)}
									</div>
								</div>
							) : (
								<>
									<Box
										style={{
											display: "flex",
											justifyContent: "center",
											textAlign: "center",
											padding: "0.5rem",
											minHeight: "10vh",
										}}
									>
										<Typography
											style={{
												...errorStyleText,
												justifyContent: "center",
												textAlign: "center",
												fontWeight: "550",
												fontSize: "1rem",
											}}
										>
											{apiError}
										</Typography>
									</Box>
								</>
							)}
						</Container>
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default OneTimeTaskReportForm;
