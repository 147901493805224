import {
	CheckBoxOutlined,
	ContentCopyOutlined,
	OpenInNew,
} from "@mui/icons-material";
import {
	Alert,
	Button,
	Container,
	Link,
	Paper,
	Snackbar,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	headingBlue,
	lightGrey,
	mediumGrey,
	pureWhite,
	red,
	textColor,
} from "../assets/colors";
import { fetchDailyTasksList } from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import {
	dailyTaskContentSheet,
	dailyTaskContentSheetWithTwitter,
} from "../data/taskCollections";
import CustomDatePicker from "./CustomDatePicker";
import { containerStyle, imgContainer, imgStyle } from "./formStyle";
import HeaderBar from "./HeaderBar";
import InfoButton from "./InfoButton";
import Loader from "./Loader";
import Header from "./Headers";
import WORKslogans from "./WORKslogans";
import BackArrow from "./BackArrow";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

const DailyTaskContentSheet = () => {
	const navigate = useNavigate();

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const [searchParams] = useSearchParams();

	// const [contactNumber, setContactNumber] = useState("");
	const [gender, setGender] = useState("MEN");
	const [dailyTaskLists, setDailyTaskLists] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [apiError, setApiError] = useState(null);

	const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
	const [isBackArrowNeeded, setIsBackArrowNeeded] = useState(false)
	const MEN_TWO_DISTRICTS_TASK_DATE =
		process.env.REACT_APP_MEN_TWO_DISTRICTS_TASK_DATE;



	useEffect(() =>{
		const propsDate = searchParams.get('reportDate')
		if ( propsDate){
			setSelectedDate(dayjs(propsDate))
			setIsBackArrowNeeded(true)
		} else{
			setIsBackArrowNeeded(false)
		}
	}, [])

	useEffect(() => {
		async function fetchTaskDetails() {

			if (selectedDate && gender) {
				try {
					setIsLoading(true);
					let token = await localStorage.getItem("token");
					let options = {
						url: fetchDailyTasksList,
						method: "GET",
						params: {
							taskDate: dayjs(dayjs(dayjs(selectedDate).format()).utc())
								.tz("Asia/Kolkata")
								.format("YYYY-MM-DD"),
							gender: gender === "MEN" ? "Men" : "Women",
						},
						headers: {
							Authorization: `Bearer ${token}`,
						},
					};
					HttpService(options)
						.then((res) => {
							console.log(res);
							setDailyTaskLists(res.data.data);
							setIsLoading(false);
						})
						.catch((err) => {
							console.log("Error in fetching daily tasks lists.", err);
							if (err.status === 409) {
								setApiError(err.response.data.description);
							} else {
								setApiError(
									"Error in fetching the task details. Please contact Support."
								);
							}

							setIsLoading(false);
						});
				} catch (err) {
					setApiError(
						"Error in fetching the task details. Please contact Support."
					);
					setIsLoading(false);
				}
			}
		}
		fetchTaskDetails();
	}, [gender, selectedDate]);

	console.log(dailyTaskLists);

	const filteredContentSheet =
		selectedDate >= dayjs(process.env.REACT_APP_TWITTER_TASK_DATE)
			? gender === "MEN" && selectedDate < dayjs(MEN_TWO_DISTRICTS_TASK_DATE)
				? dailyTaskContentSheetWithTwitter.filter((task) => !task.district2Task)
				: dailyTaskContentSheetWithTwitter
			: gender === "MEN" && selectedDate < dayjs(MEN_TWO_DISTRICTS_TASK_DATE)
			? dailyTaskContentSheet.filter((task) => !task.district2Task)
			: dailyTaskContentSheet;

	// if (isLoading) return <Loader />;

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
				}}
			>
				{/* <Header title="Tasks List"></Header> */}
				<div style={containerStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<WORKslogans />
					</div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					{isBackArrowNeeded && <BackArrow></BackArrow>}
					{isLoading && <Loader></Loader>}
					<Typography
						variant="h6"
						style={{ fontWeight: "bold", marginLeft: "0.2rem" }}
					>
						Daily Task Sheet
					</Typography>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0.1rem 0.2rem",
						}}
					>
						<div>
							<button
								style={{
									border: "1px solid",
									borderColor: mediumGrey,
									padding: "0.4rem 0.5rem",
									borderTopLeftRadius: "0.3rem",
									borderBottomLeftRadius: "0.3rem",
									backgroundColor: gender === "MEN" ? headingBlue : pureWhite,
									color: gender === "MEN" ? pureWhite : "#000000",
									cursor: "pointer",
								}}
								onClick={() => setGender("MEN")}
							>
								MEN
							</button>
							<button
								style={{
									border: "1px solid",
									borderColor: mediumGrey,
									padding: "0.4rem 0.5rem",
									borderTopRightRadius: "0.3rem",
									borderBottomRightRadius: "0.3rem",
									borderLeft: "none",
									backgroundColor: gender === "WOMEN" ? headingBlue : pureWhite,
									color: gender === "WOMEN" ? pureWhite : "#000000",
									cursor: "pointer",
								}}
								onClick={() => setGender("WOMEN")}
							>
								WOMEN
							</button>
						</div>

						<div
							style={{
								marginLeft: "auto",
							}}
						>
							<CustomDatePicker
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
							/>
						</div>
					</div>
					<Paper
						style={{
							maxHeight: "60vh",
							overflowY: "auto",
							padding: "0rem",
							scrollbarWidth: "none",
						}}
					>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.2rem",
								padding: "0.5rem 0.5rem",
							}}
						>
							{!Object.keys(dailyTaskLists).length > 0 ? (
								<Typography
									variant="h6"
									style={{ textAlign: "center", fontSize: "1rem" }}
								>
									No Tasks List Found
								</Typography>
							) : (
								<>
									{filteredContentSheet.map((task) => (
										<Paper
											style={{
												display: "grid",
												gridTemplateColumns: "0.8fr 1.2fr",
												gridTemplateRows:
													(task.isCaptionTask && !task.isLinkTask) ||
													(!task.isCaptionTask && task.isLinkTask)
														? "1fr"
														: "0.5fr 1.5fr",
												columnGap: "1rem",
												padding: "0.5rem",
											}}
											key={task.taskTitle}
										>
											<Typography
												style={{
													gridRow:
														task.isCaptionTask && !task.isLinkTask
															? "1 /span 1"
															: "1 /span 2",
													gridColumn: 1,
													alignContent: "center",
													fontSize: "0.85rem",
													color: red,
												}}
											>
												{task.taskTitle}
											</Typography>
											{task.isLinkTask ? (
												<Typography
													style={{
														display: "flex",
														alignItems: "center",
														gridRow: 1,
														gridColumn: 2,
													}}
												>
													<Link
														href={
															task.isCaptionTask
																? dailyTaskLists[task?.taskData?.link]
																: dailyTaskLists[task?.taskLink]
														}
														target="_blank"
														rel="noopener noreferrer"
														sx={{
															display: "inline-flex",
															alignItems: "center",
														}}
													>
														Link
														<OpenInNew
															sx={{ fontSize: "1rem", marginLeft: "3px" }}
														/>
													</Link>
												</Typography>
											) : (
												<></>
											)}

											{task.isCaptionTask ? (
												<Typography
													style={{
														gridRow:
															task.isCaptionTask && !task.isLinkTask ? 1 : 2,
														gridColumn: 2,
														fontSize: "0.8rem",
													}}
													variant="h6"
													component="span"
													sx={{
														border: "1px solid",
														borderColor: mediumGrey,
														borderRadius: "0.3rem",
														padding: "0.3rem",
														position: "relative",
													}}
												>
													{task.isCaptionTask
														? dailyTaskLists[task?.taskData?.caption]
														: ""}
													{task.isCaptionTask && (
														<CopyButton
															task={task}
															dailyTaskLists={dailyTaskLists}
														/>
													)}
												</Typography>
											) : (
												<></>
											)}
										</Paper>
									))}
								</>
							)}
						</Container>
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default DailyTaskContentSheet;

function CopyButton({ task, dailyTaskLists }) {
	const [copied, setCopied] = useState(false);
	function handleCopy(textToCopy) {
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => setCopied(true))
			.catch((err) => console.error("Falied to copy text:", err));
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") return; // Ignore clickaway close events
		setCopied(false);
	};

	return (
		<>
			{!copied ? (
				<ContentCopyOutlined
					sx={{ fontSize: "medium" }}
					style={{
						position: "absolute",
						bottom: "2px",
						right: "2px",
						cursor: "pointer",
					}}
					onClick={() => handleCopy(dailyTaskLists[task.taskData.caption])}
				/>
			) : (
				<CheckBoxOutlined
					sx={{ fontSize: "large" }}
					style={{
						position: "absolute",
						bottom: "2px",
						right: "2px",
						cursor: "pointer",
					}}
				/>
			)}

			<Snackbar
				open={copied}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
			>
				<Alert onClose={handleCloseSnackbar} severity="success">
					Text copied to clipboard!
				</Alert>
			</Snackbar>
		</>
	);
}
