import { Button, Container, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	fetchWeeklyTaskDetails,
	fetchWeeklyTasks,
	weeklyTaskApi,
} from "../backend/backendApis";
import { weeklyTasks } from "../data/taskCollections";
import ConfirmationWindow from "./ConfirmationWindow";
import CustomCheckbox from "./CustomCheckbox";
import {
	buttonStyle,
	containerStyle,
	imgContainer,
	imgStyle,
} from "./formStyle";
import InfoButton from "./InfoButton";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import { headingBlue } from "../assets/colors";
import HttpService from "../backend/requestApis";
import HeaderBar from "./HeaderBar";
import CustomDropDown from "./CustomDropDown";
import { getCurrentWeek, weekDropDown } from "../utils/constants";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import BackArrow from "./BackArrow";
import WORKslogans from "./WORKslogans";

const WeeklyTaskReportForm = () => {
	// const [checkboxStates, setCheckboxStates] = useState(
	// 	weeklyTasks.reduce((acc, task) => ({ ...acc, [task.taskName]: false }), {})
	// );

	const navigate = useNavigate();
	const [checkboxStates, setCheckboxStates] = useState({});
	const [gender, setGender] = useState(null);
	const [contactNumber, setContact] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [checkboxObject, setChecboxObject] = useState({});

	const [isButtonSubmit, setIsButtonSubmit] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [selectedWeek, setSelectedWeek] = useState(null);
	const [selectedWeekNumber, setSelectedWeekNumber] = useState(0);

	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [options, setOptions] = useState({});
	const [apiError, setApiError] = useState(null);

	const isMobile = useMediaQuery("(max-width:600px)");

	useEffect(() => {
		async function fetchUser() {
			try {
				setIsLoading(true);
				let contact = await localStorage.getItem("contact");
				setContact(contact);
				let weekDetails = getCurrentWeek();
				setSelectedWeek(weekDetails.displayName);
				setSelectedWeekNumber(weekDetails.weekNumber);
			} catch (err) {
				setIsLoading(false);
				navigate("/signUp");
			}
		}
		setApiError(null);
		fetchUser();
	}, []);

	useEffect(
		function () {
			if (selectedWeek) {
				if (contactNumber) {
					setIsLoading(true);
					let options = {
						url: fetchWeeklyTaskDetails,
						method: "GET",
						params: {
							contactNumber: localStorage.getItem("contact")
								? localStorage.getItem("contact")
								: null,
							weekNumber: parseInt(selectedWeekNumber),
						},
						headers: {
							Authorization: localStorage.getItem("token")
								? `Bearer ${localStorage.getItem("token")}`
								: null,
						},
					};
					HttpService(options)
						.then((res) => {
							setChecboxObject(res.data.data);
							setGender(res.data.data.gender);
							setIsLoading(false);
						})
						.catch((err) => {
							console.log("Error in fetching weekly tasks.", err);
							if (err.status === 409) {
								setApiError(err.response.data.description);
							} else {
								setApiError(
									"Error in fetching your task status. Please contact Support."
								);
							}

							setIsLoading(false);
						});
				} else {
					localStorage.clear();
					navigate("/signUp");
				}
			}
		},
		[contactNumber, selectedWeek]
	);

	useEffect(() => {
		if (Object.keys(checkboxObject).length > 0) {
			setIsLoading(true);
			setCheckboxStates(() =>
				weeklyTasks.reduce(
					(acc, task) => ({
						...acc,
						[task.taskName]: checkboxObject[task.taskName] || false,
					}),
					{}
				)
			);
			setIsLoading(false);
		}
	}, [checkboxObject]);

	const getDistricts = () => {
		if (checkboxObject.gender === "Female") {
			return {
				firstDistrict: checkboxObject.selectedDistrict,
				secondDistrict: checkboxObject.secondSelectedDistrict,
			};
		} else {
			return null;
		}
	};

	const districts = getDistricts();

	function closeWindow() {
		setIsOpenWindow(false);
		setIsButtonSubmit(false);
	}

	const handleCheckboxChange = (taskName) => {
		setCheckboxStates((prevState) => ({
			...prevState,
			[taskName]: !prevState[taskName],
		}));
	};
	// Function to get records of checked checkboxes
	const getCheckedTasks = () => {
		return Object.keys(checkboxStates).filter(
			(taskName) => checkboxStates[taskName] === true
		);
	};

	const isAllTasksCompleted =
		gender === "Male"
			? getCheckedTasks().length === weeklyTasks.length - 2
			: getCheckedTasks().length === weeklyTasks.length;

	async function handleSubmit() {
		try {
			setApiError(null);
			setIsOpenWindow(true);
			setIsButtonSubmit(true);

			if (getCheckedTasks().length === 0) return;

			let token = await localStorage.getItem("token");
			if (token) {
				checkboxStates.contactNumber = contactNumber;
				checkboxStates.gender = gender;
				checkboxStates.weekNumber = Number(selectedWeek.split(" ").at(1));
				let apiOptions = {
					url: weeklyTaskApi,
					method: "POST",
					payload: checkboxStates,
					headers: {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				};
				setOptions(apiOptions);
			} else {
				// setIsLoading(false)
				navigate("/signUp");
			}
		} catch (err) {
			console.log("Error: ", err);
			setApiError(null);
		}
	}

	function updateWeek(option) {
		setSelectedWeek(option.displayName);
		setSelectedWeekNumber(option.weekNumber);
	}

	if (isLoading) return <Loader />;

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
				}}
			>
				<HeaderBar isReportForm={true} />
				<div style={containerStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<WORKslogans />
					</div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<BackArrow />
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0rem 0.2rem",
						}}
					>
						<h3>Weekly Task</h3>

						{!isFormSubmitted && (
							<CustomDropDown
								width={isMobile ? "12rem" : "14rem"}
								regular={true}
								list={weekDropDown}
								value={selectedWeek}
								setFunc={updateWeek}
								isValueRequired={true}
							></CustomDropDown>
						)}
					</div>
					<Paper
						style={{
							maxHeight: "50vh",
							overflowY: "auto",
							padding: "0rem 0.2rem 0.7rem 0.2rem",
							scrollbarWidth: "none",
						}}
					>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.2rem",
								padding: "1rem 0.5rem",
							}}
						>
							{isFormSubmitted ? (
								<ReportFormSubmitMessage />
							) : (
								<div>
									{weeklyTasks.map((task) => (
										<CustomCheckbox
											taskId={task.taskId}
											key={task.taskId}
											label={task.taskTitle}
											description={task.taskDescription}
											isCheckboxChecked={checkboxStates[task.taskName || false]}
											setIsCheckboxChecked={() =>
												handleCheckboxChange(task.taskName)
											}
											districts={districts}
											taskName={task.taskName}
										/>
									))}
									<div
										style={{
											textAlign: "center",
										}}
									>
										<Button
											style={{
												...buttonStyle,
												fontSize: "0.8rem",
											}}
											onClick={handleSubmit}
										>
											Submit
										</Button>
										{isButtonSubmit && (
											<ConfirmationWindow
												windowStatus={isOpenWindow}
												updateWindowStatus={closeWindow}
												isAllTasksCompleted={isAllTasksCompleted}
												options={options}
												isNoTaskChecked={getCheckedTasks().length === 0}
												setIsFormSubmitted={setIsFormSubmitted}
												setIsLoading={setIsLoading}
												setApiError={(err) => {
													setApiError(err);
												}}
											/>
										)}
									</div>
								</div>
							)}
						</Container>
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default WeeklyTaskReportForm;
