import React from 'react'
import MissingDailyTasks from './MissingDailyTasks'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { headingBlue } from '../assets/colors'

const PendingTasks = (props) => {

    var titleStyle={
        backgroundColor: headingBlue,
        color: 'white',
        fontSize:'1rem',
        display: "flex",
        alignItems: 'center'
    }
  return (
    <Dialog maxWidth='sm' fullWidth open={props.pendingTaskDialogStatus} onClose={() => props.updatePendingDialogStatus()}>
        {/* <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"}} style={{titleStyle}}>
            PendingTasks
        </DialogTitle> */}
        <DialogContent style={{ padding: "1rem 0.7rem 0.7rem 0.7rem"}}>
            <MissingDailyTasks isDialog={true} selectedMember={props.selectedMember}></MissingDailyTasks>
        </DialogContent>

    </Dialog>
  )
}

export default PendingTasks