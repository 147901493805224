import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { buttonStyle, headerBoxStyle, headerCellStyle, iconStyle, internalBoxStyle, outerBoxStyle, paginationInnerBox, paginationOuterBox, paperStyle, tableHeaderBoxStyle, tableIconBoxStyle } from './ReportDashBoardStyle'
import CustomDropDown from './CustomDropDown'
import { reportTableFilterOption, tableFilterOption, tableOffsets } from '../utils/constants'
import CustomTextField from './CustomTextField'
import { headingBlue, lightGrey, maroon, mediumGrey, textColor } from '../assets/colors'
import { ExpandLess, ExpandMore, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import Loader from './Loader'
import TaskDetailsDialogBox from './TaskDetailsDialogBox'
import { dailyTasks, dailyTasksWithTwitter, monthlyOfflineTasks, monthlyOnlineTasks, oneTimeTasks, weeklyTasks } from '../data/taskCollections'
import DownloadDialogBox from './DownloadDialogBox'
import dayjs from 'dayjs'
import MissingDailyTasks from './MissingDailyTasks'
import PendingTasks from './PendingTasks'

const ReportsTable = (props) => {
    const [dialogStatus, setDialogStatus] = React.useState(false)
    const [detailsDialogStatus, setDetailsDialogStatus] = React.useState(false)
    const [dialogData, setDialogData] = React.useState(null)
    const [taskList, setTaskList] = React.useState([])
    const [pendingtaskDialogStatus, setPendingTaskDialogStatus] = React.useState(false)
    const [selectedMember, setSelectedMember] = React.useState(null)

    const updateDownloadDialogStatus = (status) => {
        setDialogStatus(status)
    }

    const updateDetailsDialogStatus = (status) => {
        setDetailsDialogStatus(status)
    }

    const updatePendingDialogStatus = (status) => {
        setPendingTaskDialogStatus(status)
    }

    React.useEffect(() => {
        setTaskList(() => [])
        switch(props.dashBoard){
            case 'Daily Task':
                dayjs(props.selectedDate) >= dayjs(process.env.REACT_APP_TWITTER_TASK_DATE) ? 
                setTaskList(() => dailyTasksWithTwitter) : 
                setTaskList(() => dailyTasks)
                break;
            case 'One Time Task':
                setTaskList(() => oneTimeTasks)
                break;
            case 'Weekly Task':
                setTaskList(() => weeklyTasks)
                break;
            case 'Monthly Task':
                let taskList = [...monthlyOfflineTasks, ...monthlyOnlineTasks]
                setTaskList(() => taskList)
        }
    }, [props.dashBoard, props.selectedDate])
  return (
    <>
    <Box style={outerBoxStyle}>
        <Paper style={paperStyle}>
            <Box style={headerBoxStyle}>
                <Box style={internalBoxStyle}>
                    <Typography>WORKers Table</Typography>
                </Box>
                <Box style={{ display:'flex', flex: "1 1", width: "80%", justifyContent: 'flex-end', padding: "0rem 0.5rem 0rem 0.5rem", flexWrap: 'wrap', gap: '0.5rem'}}>
                    <CustomDropDown key='tableFilters' width="11rem" list={reportTableFilterOption} value={props.filterOnValue} placeHolder='Filter On' setFunc={props.updateFilterOn} regular={true}></CustomDropDown>
                    <CustomTextField placeHolder="Enter Text to Filter" value={props.filterByValue} setFunc={props.updateFilterBy}></CustomTextField>
                    <Button variant='outlined' sx={buttonStyle} style={{ textTransform: 'capitalize' }} onClick={updateDownloadDialogStatus}> Download</Button>
                </Box>

            </Box>
            <Box style={{display: 'flex', justifyContent: 'center', overflow: 'auto'}}>
                <TableContainer component={Paper} style={{width: '98%', height: "38vh", border: '1px solid #D9D9D9', marginTop: "0.2rem", "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none"}}>
                    <Table stickyHeader>
                        <TableHead  style={{backgroundColor: mediumGrey}}>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={{...tableHeaderBoxStyle, color: headingBlue}}>
                                    WORKer
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem', color: headingBlue}} size='small' onClick={() => {
                                        props.updateSort('name', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem', color: headingBlue}} size='small' onClick={() => {
                                        props.updateSort('name', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Contact Number
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('contactNumber', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('contactNumber', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Alliance
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('allianceName', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('allianceName', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Supervisor
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('supervisorName', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}}  size='small' onClick={() => {
                                        props.updateSort('supervisorName', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Team Leader
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('leaderName', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('leaderName', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Tasks Done
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('taskDone', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} size='small' onClick={() => {
                                        props.updateSort('taskDone', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                <Box style={tableHeaderBoxStyle}>
                                    Tasks Pendings
                                    <Box style={tableIconBoxStyle}>
                                    <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}}  size='small' onClick={() => {
                                        props.updateSort('taskPending', 1)
                                        }}>
                                        <ExpandLess fontSize='small'></ExpandLess>
                                    </IconButton>
                                    <IconButton style={{...iconStyle, marginTop: '-0.2rem'}}  size='small' onClick={() => {
                                        props.updateSort('taskPending', -1)
                                        }}>
                                        <ExpandMore fontSize='small'></ExpandMore>
                                    </IconButton>

                                    </Box>
                                </Box>
                            </TableCell>
                        </TableHead>
                        { props.loading ? 
                            <Loader></Loader> :
                        
                            <TableBody style={{ textAlign: 'center', overflowY: 'auto'}}>
                                { props?.data?.data?.map((row,index) => { return(
                                    <TableRow key={{index}}>
                                        {row.isLeader ? 
                                            <TableCell  style={{...headerCellStyle,color: headingBlue}}>{row.name ? row.name + ' (I)' : '-'}</TableCell> :
                                            <TableCell  style={{...headerCellStyle,color: headingBlue}} 
                                                onClick = {() => {
                                                        setSelectedMember(row.contactNumber)
                                                        updatePendingDialogStatus(true)
                                                    }
                                                }
                                            >{row.name ? row.name  : '-'}</TableCell>
                                        }
                                        <TableCell  style={{...headerCellStyle, fontSize: '0.85rem'}}>{row.contactNumber ? row.contactNumber : '-'}</TableCell>
                                        <TableCell  style={{...headerCellStyle, fontSize: '0.85rem'}}>{row.allianceName ? row.allianceName : '-'}</TableCell>
                                        <TableCell  style={{...headerCellStyle, fontSize: '0.85rem'}}>{row.supervisorName ? row.supervisorName : '-'}</TableCell>
                                        <TableCell  style={{...headerCellStyle, fontSize: '0.85rem'}}>{row.leaderName ? row.leaderName : '-'}</TableCell>
                                        {row.districtsAssigned != 0 ?
                                        (<TableCell  style={{...headerCellStyle, fontSize: '0.85rem', color: textColor}}
                                            onClick={() => {
                                                updateDetailsDialogStatus(true)
                                                setDialogData(row)
                                            }}
                                        >
                                            {row.taskDone ? row.taskDone : 0}
                                        </TableCell>) :
                                            (
                                                <TableCell  style={{...headerCellStyle, fontSize: '0.85rem', color: textColor}}
                                            >
                                                {0}
                                            </TableCell>)
                                        }
                                        {row.districtsAssigned != 0 ?
                                        (<TableCell  style={{...headerCellStyle, fontSize: '0.8rem', color: textColor}}
                                            onClick={() => {
                                                updateDetailsDialogStatus(true)
                                                setDialogData(row)
                                            }}
                                        >{row.taskPending ? row.taskPending : '0'}</TableCell>) :
                                            (
                                                <TableCell  style={{...headerCellStyle, fontSize: '0.85rem', color: textColor}}
                                            >
                                                {0}
                                            </TableCell>)
                                        }
                                    </TableRow>
                                )})
                                }

                            </TableBody>
                        }

                    </Table>

                </TableContainer>


            </Box>

            <Box style={paginationOuterBox}>
                <Box style={{width: "25%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: "0rem 0.5rem 0rem 0.9rem"}}>
                    <Typography style={{color: maroon}}>
                    {((props?.pageValue - 1) * props?.offsetValue) +1} - {props?.pageValue * props?.offsetValue} of {props?.data?.totalRecords}
                    </Typography>
                </Box>
                
                <Box style={{...paginationInnerBox}}>
                    <IconButton size='small'
                        onClick={() => {
                            if(props.pageValue > 1){
                              props.updatePage(props.pageValue - 1)
                            }
                          }}
                    >
                        <KeyboardArrowLeft size='small' sx= {{color: props.pageValue === 1 ? mediumGrey: headingBlue}}></KeyboardArrowLeft>
                    </IconButton>
                    <Button variant='contained' style={{minWidth: '0rem', width: '1.5rem', height: '1.5rem',borderRadius: '50%', backgroundColor: headingBlue, color: 'white', padding: '0rem', alignItems: 'center'}}>{props.pageValue}</Button>
                    <IconButton size='small'
                        onClick={() => {
                            if(props.pageValue < props?.data?.totalPages){
                              props.updatePage(props.pageValue + 1)
                            }
                          }}
                    >
                        <KeyboardArrowRight size='small' sx= {{color: (props.pageValue === props?.data?.totalPages || props?.data?.totalPages === 0) ? mediumGrey: headingBlue}}></KeyboardArrowRight>
                    </IconButton>

                </Box>
                <Box style={{width: '25%',display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem'}}>
                <CustomDropDown key='table' value={props.offsetValue} width="5rem" setFunc={props.updateOffset} list={tableOffsets} regular={true}></CustomDropDown>
                </Box>

            </Box>

        </Paper>

    </Box>
    <DownloadDialogBox dialogStatus= {dialogStatus} updateDownloadDialogStatus={updateDownloadDialogStatus} data={props?.data?.data} isReport={true} dashBoard={props.dashBoard} selectedDate={props.selectedDate}></DownloadDialogBox>
    <TaskDetailsDialogBox detailsDialogStatus={detailsDialogStatus} setDetailsDialogStatus={setDetailsDialogStatus} data={dialogData} taskList={taskList}></TaskDetailsDialogBox>
    <PendingTasks selectedMember={selectedMember} pendingTaskDialogStatus={pendingtaskDialogStatus} updatePendingDialogStatus={updatePendingDialogStatus}></PendingTasks>
    </>
  )
}

export default ReportsTable