import { fontSize } from "@mui/system";
import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnalogClock from "./AnalogClock";
import AnimatedNumbers from "./AnimatedNumbers";

const Countdown = ({ targetDate }) => {
	const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeLeft(getTimeLeft(targetDate));
		}, 1000);

		return () => clearInterval(interval);
	}, [targetDate]);

	function getTimeLeft(target) {
		const now = new Date();
		const diff = Math.max(target - now, 0); // Avoid negative values

		const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
		const days = Math.floor(diff / (1000 * 60 * 60 * 24));

		return { weeks, days };
	}

	return (
		<>
			<div style={styles.container}>
				<TimeCircle
					label="WEEKS"
					value={timeLeft.weeks}
					max={100}
					isDaysCircle={false}
				/>
				<AnalogClock />
				<TimeCircle
					label="DAYS"
					value={timeLeft.days}
					max={650}
					isDaysCircle={true}
				/>
			</div>
		</>
	);
};

const TimeCircle = ({ label, value, max, isDaysCircle }) => {
	const pathColor =
		label === "DAYS" ? "rgba(247, 19, 60, 1)" : "rgba(49, 130, 206, 1)";
	const trailColor =
		label === "DAYS" ? "rgba(247, 19, 60, 0.2)" : "rgba(49, 130, 206, 0.3)";

	return (
		<div style={styles.circleContainer}>
			<div style={styles.circle}>
				{/* Circular Progress Bar */}
				<CircularProgressbar
					value={(value / max) * 100}
					// text={`${value}`}
					styles={buildStyles({
						pathColor: pathColor,
						trailColor: trailColor,
						textSize: "0rem", // Hide default text
					})}
				/>

				{/* Animated Numbers  */}
				<div style={styles.numberOverlay}>
					<AnimatedNumbers value={value} isDaysCircle={isDaysCircle} />
				</div>
			</div>
			<span style={styles.label}>
				{value === 1 ? label.slice(0, -1) : label}
			</span>
		</div>
	);
};

const styles = {
	container: {
		display: "flex",
		justifyContent: "center",
		gap: "0.5rem",
		padding: "0rem 0.5rem 1rem 0rem",
		height: "2rem",
	},
	circleContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "2.4rem",
		position: "relative",
	},
	circle: {
		width: "2.1rem",
		height: "2.1rem",
		position: "relative",
	},
	numberOverlay: {
		position: "absolute",
		top: "55%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		fontSize: "1rem",
		color: "#2D2D2D",
	},
	label: {
		marginTop: "0.1rem",
		fontSize: "0.7rem",
		fontWeight: "bold",
		color: "#333",
	},
};

export default Countdown;
