import { Box, Container, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOneTimeTaskDetails, getReferals } from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import { containerStyle, imgContainer, imgStyle } from "./formStyle";
import HeaderBar from "./HeaderBar";
import {
	green,
	headingBlue,
	lightGrey,
	maroon,
	mediumGrey,
	pureWhite,
	red,
	yellowish,
} from "../assets/colors";
import ConfirmationWindow from "./LeadsConfirmationWindow";
import Loader from "./Loader";
import { commonErrorStyleText } from "../utils/commonCSS";
import BackArrow from "./BackArrow";
import WORKslogans from "./WORKslogans";

const OneTimeTaskReportForm = () => {
	const [contactNumber, setContactNumber] = useState("");
	const [gender, setGender] = useState(null);

	const [leads, setLeads] = useState([]);

	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState({});
	const [apiError, setApiError] = useState(null);
	const [rowIndex, setRowIndex] = useState(null);
	const [refreshApi, setRefreshApi] = useState(false);
	const [leadStatuses, setLeadStatuses] = useState(
		Array(leads.length).fill("Pending")
	);

	const navigate = useNavigate();

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// const gridColumnStyle = isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)";

	useEffect(() => {
		async function fetchUser() {
			try {
				setIsLoading(true);
				let contactNumber = await localStorage.getItem("contact");
				if (contactNumber) {
					setContactNumber(contactNumber);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					localStorage.clear()
					navigate("/signUp");
				}
			} catch (err) {
				setIsLoading(false);
				localStorage.clear();
				navigate("/signUp");
			}
		}
		setApiError(null);
		fetchUser();
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (contactNumber) {
				try {
					setIsLoading(true);
					let token = await localStorage.getItem("token");
					if (token) {
						let options = {
							url: getReferals,
							method: "GET",
							params: {
								contactNumber: localStorage.getItem("contact"),
							},
							headers: {
								Authorization: `Bearer ${token}`,
							},
						};
						setIsLoading(true);
						HttpService(options)
							.then((res) => {
								console.log(res);
								setLeads(res?.data?.data);
								setIsLoading(false);
							})
							.catch((err) => {
								console.log("Error in fetching daily tasks.", err);
								if (err?.status === 403) {
									localStorage.clear();
									setIsLoading(false);
									// setLoadingTableData(false)
									navigate("/signUp");
								} else if (err.status === 409) {
									setApiError(err.response.data.description);
								} else {
									if (err.status === 404) {
										setApiError("Please Select the District first.");
									} else
										setApiError(
											"Error in fetching your task status. Please contact Support."
										);
								}
								setIsLoading(false);
							});
					} else {
						setIsLoading(false);
						navigate("/signUp");
					}
				} catch (err) {
					if (err?.status === 403) {
						localStorage.clear();
						setIsLoading(false);
						// setLoadingTableData(false)
						navigate("/signUp");
					} else {
						setApiError("Error in fetching your task status. Please contact Support.");
						setIsLoading(false);
					}
				}
			}
		}
		fetchData();
	}, [contactNumber, refreshApi]);

	function closeWindow(refresh = false) {
		setIsOpenWindow(false);
		if (refresh) {
			setRefreshApi(!refreshApi);
		}
	}

	// if (isLoading) return <Loader />;
	return (
		<div
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				overflow: "auto",
			}}
		>
			<HeaderBar isReportForm={true} />
			<div style={{ ...containerStyle, maxWidth: "28rem" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<WORKslogans />
				</div>
				<div style={imgContainer}>
					<img
						src={require("../assets/images/WORK_logo.png")}
						alt="Logo"
						style={imgStyle}
						onClick={() => navigate("/")}
					></img>
				</div>
				<BackArrow />
				<Typography
					style={{ fontSize: "1.2rem", fontWeight: 600, padding: "0.1rem" }}
				>
					{" "}
					Leads
				</Typography>
				<Paper
					style={{
						maxHeight: "55vh",
						overflowY: "auto",
						padding: "0rem",
						scrollbarWidth: "none",
					}}
				>
					{isLoading && <Loader></Loader>}
					<Container
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: "0.2rem",
							padding: "1rem 0.5rem",
						}}
					>
						<>
							{leads.length > 0 ? (
								leads?.map((lead, index) => (
									<LeadRow
										isMobile={isMobile}
										lead={lead}
										key={lead?.contactNumber}
										setIsOpenWindow={setIsOpenWindow}
										setRowIndex={setRowIndex}
										index={index}
									/>
								))
							) : (
								<Box style={{ color: maroon }}>
									<Typography
										style={{ textAlign: "center", color: { headingBlue } }}
									>
										No Leads for your Selected District, keep trying InshaAllah
										You will get Leads soon.
									</Typography>
								</Box>
							)}
						</>
					</Container>
					{isOpenWindow && (
						<ConfirmationWindow
							windowStatus={isOpenWindow}
							updateWindowStatus={closeWindow}
							setIsLoading={setIsLoading}
							setApiError={(err) => {
								setApiError(err);
							}}
							isMobile={isMobile}
							lead={leads[rowIndex]}
						/>
					)}
					{apiError && (
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								padding: "1rem",
							}}
						>
							<Typography
								style={{ ...commonErrorStyleText, fontSize: "1.1rem" }}
							>
								{apiError}
							</Typography>
						</Box>
					)}
				</Paper>
			</div>
		</div>
	);
};

export default OneTimeTaskReportForm;

function LeadRow({ lead, isMobile, setIsOpenWindow, setRowIndex, index }) {
	function handleClick() {
		setIsOpenWindow(true);
		setRowIndex(index);
	}

	return (
		<>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "0.2fr 1.8fr 1fr 1.2fr 1.8fr",
					gap: "0.7rem",
					border: "1px solid",
					borderColor: mediumGrey,
					borderRadius: "0.2rem",
					padding: "0.2rem 0.4rem",
					cursor: "pointer",
					backgroundColor: pureWhite,
					alignItems: "center",
				}}
				onMouseOver={(e) => (e.currentTarget.style.backgroundColor = lightGrey)}
				onMouseOut={(e) => (e.currentTarget.style.backgroundColor = pureWhite)}
				onClick={handleClick}
			>
				<Typography sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem" }}>
					{index + 1}
				</Typography>
				<Typography sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem" }}>
					{lead.name}
				</Typography>
				<Typography
					sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem" }}
					component="a"
					href={`tel:${lead.contactNumber}`}
				>
					{lead.contactNumber}
				</Typography>
				<Typography
					sx={{
						fontSize: isMobile ? "0.8rem" : "0.9rem",
						display: "flex",
						justifyContent: "center",
					}}
				>
					{lead.district}
				</Typography>
				{lead.leadStatus === "wrongNumber" ? (
					<Typography
						sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem", color: red }}
					>
						Wrong Number
					</Typography>
				) : lead.leadStatus === "notReachable" ? (
					<Typography
						sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem", color: red }}
					>
						Not Reachable
					</Typography>
				) : lead.leadStatus === "followUp" ? (
					<Typography
						sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem", color: yellowish }}
					>
						Follow UP
					</Typography>
				) : lead.leadStatus === "closed" ? (
					<Typography
						sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem", color: green }}
					>
						Closed
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: isMobile ? "0.8rem" : "0.9rem",
							color: headingBlue,
						}}
					>
						Not Initiated
					</Typography>
				)}
			</Box>
		</>
	);
}
