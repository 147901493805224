import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react'
import { headingBlue, maroon } from '../assets/colors';

const LeadsTopBar = (props) => {
  var topBoxStyle={
    display: 'flex',
    flexWrap: 'wrap',
    width: '97.5%',
    border: '1px solid #D9D9D9',
    padding: '0.1rem 0.3rem 0.1rem 1.6rem',
    // marginTop: "10rem"
    }

    var internalBoxStyle = {
        display: "flex",
        justifyContent: "flex-start",
        flex: "1 1",
        alignItems: "center",
        width: "47%",
        padding: "0.1rem 2rem",
        flexDirection: "row",
    };
    var typographyStyle = {
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        textAlign: "center",
        verticalAlign: "center",
        // marginLeft: '1.2rem'
    };

    var buttonStyle = {
        color: "black",
        border: "1px solid #D9D9D9",
        marginRight: "0.3rem",
        fontFamily: "Helvetica",
        fontWeight: "500",
        fontSize: "0.9rem",
  };

  return (
    <>
      <Box style={topBoxStyle}>
          <ToggleButtonGroup
              color="primary"
              value={props.toggle}
              exclusive
              sx={{
                  display: "flex",
                  "& .MuiToggleButton-root": {
                      fontSize: "0.8rem",                       
                      padding: "4px 8px ",                       
                      minWidth: "50px",
                      color: headingBlue,
                      "&.Mui-selected": {
                      backgroundColor: headingBlue,
                      color: "#fff",
                      }
                  },
                  }}                        
              onChange={(event) => {
                  props.setFunc(event.target.value)
              }}
          >
              <ToggleButton value='assignedLeads'>Assigned Leads</ToggleButton>
              <ToggleButton value='unAssignedLeads'>Unassigned Leads</ToggleButton>
          </ToggleButtonGroup>


      </Box>
    </>
  )
}

export default LeadsTopBar