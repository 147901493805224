import { Box, Button, Grid2, Typography } from "@mui/material";
import React from "react";
// import { quarterList, tableOffsets } from '../utils/constants'

import CustomDropDown from './CustomDropDown'
import { dashboardsList, monthDropDown, weekDropDown } from '../utils/constants'
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { pureWhite } from '../assets/colors';
import CustomTilesDropDown from './customTilesDropDown';
import CustomDatePicker from './CustomDatePicker';
import CustomMenu from './CustomMenu';

const ActionBar = (props) => {
	const navigate = useNavigate();

	const [designation, setDesignation] = React.useState(null);
	var topBoxStyle = {
		display: "flex",
		flexWrap: "wrap",
		width: "99.5%",
		border: "1px solid #D9D9D9",
		padding: "0.1rem 0.3rem 0.1rem 0rem",
		// marginTop: "10rem"
	};

	var internalBoxStyle = {
		display: "flex",
		justifyContent: "flex-start",
		flex: "1 1",
		alignItems: "center",
		width: "47%",
		padding: "0.1rem 2rem",
		flexDirection: "row",
	};
	var typographyStyle = {
		display: "flex",
		justifyContent: "center",
		alignItem: "center",
		textAlign: "center",
		verticalAlign: "center",
		// marginLeft: '1.2rem'
	};

	var buttonStyle = {
		color: "black",
		border: "1px solid #D9D9D9",
		marginRight: "0.3rem",
		fontFamily: "Helvetica",
		fontWeight: "500",
		fontSize: "0.9rem",
	};

	React.useEffect(() => {
		async function getDesignation() {
			let designation = await localStorage.getItem("designation");
			setDesignation(designation);
		}

		getDesignation();
	}, []);

	return (
		<Box style={topBoxStyle}>
			{!(props.dashBoard === "leads" ) && (
				<Button
					variant="outlined"
					sx={{ ...buttonStyle, color: pureWhite, border: "1px solid white" }}
				>
					A
				</Button>
			)}

			{props.dashBoard === "leads"  && (
				<>
					<CustomMenu
						designation={designation}
						tableData={props.tableData}
						setTableData={props.setTableData}
						setIsTileFilter={props.setIsTileFilter}
						setIsCustomMenuFilter={props.setIsCustomMenuFilter}
						setSelectedHierarchyFilterKey={props.setSelectedHierarchyFilterKey}
						setSelectedHeirarchyFilterContact={
							props.setSelectedHeirarchyFilterContact
						}
						setLeadStatusCheckbox={props.setLeadStatusCheckbox}
						setLeadResponseFilterArray={props.setLeadResponseFilterArray}
						setStatusFilterArray={props.setStatusFilterArray}
						setIsApplyFilterButtonClicked={props.setIsApplyFilterButtonClicked}
						setSelectedTile={props.setSelectedTile}
					></CustomMenu>
				</>
			)}

			{designation === "admin" && props.isAdmin && (
				<Box style={{ ...internalBoxStyle, justifyContent: "flex-end" }}>
					<Button
						variant="outlined"
						sx={buttonStyle}
						style={{ textTransform: "capitalize" }}
						onClick={() => {
							props.updateCreateLeaderAccDialogStatus();
						}}
					>
						Create Leader Account
					</Button>

					<Button
						variant="outlined"
						sx={buttonStyle}
						style={{ textTransform: "capitalize" }}
						onClick={() => {
							props.updateAddLeaderSupervisorDialogStatus();
						}}
					>
						Add Supervisor
					</Button>

					<Button
						variant="outlined"
						sx={buttonStyle}
						style={{ textTransform: "capitalize" }}
						onClick={() => {
							props.updateCreateAllianceDialogStatus();
						}}
					>
						Create an Alliance
					</Button>

					<Button
						variant="outlined"
						sx={buttonStyle}
						style={{ textTransform: "capitalize" }}
						onClick={() => {
							props.updateEditAllianceDialogStatus();
						}}
					>
						Edit an Alliance
					</Button>
				</Box>
			)}

			{props.dashBoard === "Daily Task" && (
				<>
					<Box
						style={{
							...internalBoxStyle,
							justifyContent: "flex-end",
							alignItem: "centre",
						}}
					>
						{designation === "admin" && (
							<>
								<Button
									variant="outlined"
									sx={buttonStyle}
									style={{ textTransform: "capitalize" }}
									onClick={() => {
										// props.updateEditAllianceDialogStatus();
										props.updateTaskDialogStatus();
									}}
								>
									Add Tasks
								</Button>
							</>
						)}
						<CustomDatePicker
							selectedDate={props.selectedDate}
							setSelectedDate={props.setSelectedDate}
						></CustomDatePicker>
					</Box>
				</>
			)}

			{props.dashBoard === "Weekly Task" && (
				<>
					<Box style={{ ...internalBoxStyle, justifyContent: "flex-end" }}>
						<CustomTilesDropDown
							key="week"
							list={weekDropDown}
							regular={true}
							width="10rem"
							value={props.selectedWeek}
							setFunc={props.updateSelectedWeek}
						></CustomTilesDropDown>
						{/* <CustomDropDown key='week' list={weekDropDown} regular={true} width="15rem" value={props.selectedWeek} setFunc={props.updateSelectedWeek}></CustomDropDown> */}
					</Box>
				</>
			)}

			{
				props.dashBoard === 'Monthly Task' &&
				<>
					<Box style={{...internalBoxStyle, justifyContent: 'flex-end'}}>
						<CustomTilesDropDown key= 'month' list={monthDropDown} regular={true} width='10rem' value={props.selectedMonth} setFunc={props.updateSelectedMonth}></CustomTilesDropDown>
					</Box>
				</>
			}
		</Box>
	);
};

export default ActionBar;
