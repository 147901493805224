import React from "react";
import { headingBlue, mediumGrey } from "../assets/colors";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../utils/commonFunctions";
import CustomDropDown from "./CustomDropDown";
import { dashboardsList } from "../utils/constants";
import Countdown from "./Countdown";

const HeaderBar = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dashboard = location.state || null;

	const [selectedDashboard, setSelectedDashboard] =
		React.useState("Daily Task");

	const updateDashboard = (dashBoard) => {
		setSelectedDashboard(() => dashBoard);
		props.setFunc(dashBoard);
		// if(dashBoard === 'Admin'){
		//   navigate('/adminDashboard')
		// } else {
		//   navigate('/dashboard', {
		//     state: {
		//       "dashboard": dashBoard
		//     }
		//   })
		// }
	};

	React.useEffect(() => {
		if (props.isAdmin) {
			setSelectedDashboard("Members");
		} else {
			if (dashboard) {
				setSelectedDashboard(() => dashboard?.dashboard);
			} else if (!props.isReportForm) {
				setSelectedDashboard(() => "Daily Task");
				props.setFunc("Daily Task");
			}
		}
	}, []);

	var buttonStyle = {
		backgroundColor: headingBlue,
		color: "white",
		// marginRight: '0.2rem',
		marginLeft: "0.7rem",
		fontFamily: "Helvetica",
		fontWeight: "300",
		fontSize: "0.8rem",
		padding: "0rem 1rem 0rem 1rem",
	};
	return (
		<>
			<nav
				className="navbar navbar-expand-lg"
				style={{ backgroundColor: mediumGrey }}
			>
				<div
					className="container-fluid"
					style={{ padding: "0.5rem 2.5rem 0.5rem 0.5rem" }}
				>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-start",
								marginLeft: "1rem",
							}}
						>
							<Countdown targetDate={new Date("2026-08-26T23:59:59")} />
						</Box>
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-start",
								width: "50%",
								marginLeft: "1rem",
							}}
						>
							{/* <img style={{width: "4rem", height: '2rem'}} src={require('../assets/images/WORK_logo.png')}></img> */}
						</Box>

						<Box
							style={{
								width: "50%",
								height: "2rem",
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<a
								className="navbar-brand mx-auto"
								style={{
									fontSize: "1rem",
									color: mediumGrey,
									textAlign: "center",
									padding: "0.5rem",
								}}
							>
								"
							</a>
							{/* <Button variant='outlined' sx={buttonStyle} style={{ textTransform: 'capitalize' }}>Add Session</Button> */}
							{!props.isReportForm && (
								<CustomDropDown
									width="10rem"
									regular={true}
									list={dashboardsList}
									value={selectedDashboard}
									setFunc={updateDashboard}
								></CustomDropDown>
							)}
							<Button
								variant="outlined"
								sx={buttonStyle}
								style={{ textTransform: "capitalize" }}
								onClick={async () => {
									await localStorage.clear();
									if (props.isReportForm) {
										await logOut(navigate);
									} else {
										navigate("/");
									}
								}}
							>
								Logout
							</Button>
						</Box>
					</Box>
				</div>
			</nav>
		</>
	);
};

export default HeaderBar;
