import React from "react";
import { slogansData } from "../utils/constants";

const WORKslogans = () => {
	return (
		<div className="marquee-container">
			<div className="marquee">
				{slogansData.map((slogan, index) => (
					<p key={index} className="marquee-item">
						{slogan}
					</p>
				))}
			</div>
		</div>
	);
};

export default WORKslogans;
