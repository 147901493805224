import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import DailyTaskContentSheet from "./components/DailyTaskContentSheet";
import DailyTaskReportForm from "./components/DailyTaskReportForm";
import DistrictSelectionForm from "./components/DistrictSelectionForm";
import HomePage from "./components/HomePage";
import LoginScreen from "./components/LoginScreen";
import MissingDailyTasks from "./components/MissingDailyTasks";
import NewLeads from "./components/NewLeads";
import NewMonthlyTaskReportForm from "./components/NewMonthlyTaskReportForm";
import OneTimeTaskReportForm from "./components/OneTimeTaskReportForm";
import ReportDashboard from "./components/ReportDashBoard";
import ReportForms from "./components/ReportForms";
import ReportLoginForm from "./components/ReportLoginFrom";
import ReportSignup from "./components/ReportSignup";
import ShareReferralForm from "./components/shareReferralForm";
import WeeklyTaskReportForm from "./components/WeeklyTaskReportForm";

function App() {
	return (
		<>
			<div>
				<BrowserRouter>
					<Routes>
						<Route
							path="/dsf"
							element={<DistrictSelectionForm></DistrictSelectionForm>}
						></Route>
						{/* <Route path="/adminDashboard" element={<Dashboard></Dashboard>}></Route> */}
						<Route path="/Login" element={<LoginScreen></LoginScreen>}></Route>
						<Route path="/" element={<HomePage />}></Route>
						<Route path="/report-login" element={<ReportLoginForm />}></Route>
						<Route path="/signup" element={<ReportSignup />}></Route>
						<Route path="/reportForm" element={<ReportForms />}></Route>
						{/* <Route path="/dashboard" element={<ReportDashboard />}></Route> */}
						<Route
							path="/dashboard"
							element={<ReportDashboard></ReportDashboard>}
						></Route>
						<Route
							path="/one-time-task"
							element={<OneTimeTaskReportForm />}
						></Route>
						<Route
							path="/monthly-task"
							element={<NewMonthlyTaskReportForm />}
						></Route>
						<Route
							path="/weekly-task"
							element={<WeeklyTaskReportForm />}
						></Route>
						<Route path="/daily-task" element={<DailyTaskReportForm />}></Route>
						<Route
							path="/content-sheet"
							element={<DailyTaskContentSheet />}
						></Route>
						<Route path="/leads" element={<NewLeads />}></Route>
						<Route
							path="/shareReferral"
							element={<ShareReferralForm />}
						></Route>
						<Route
							path="/missingTasks"
							element={<MissingDailyTasks></MissingDailyTasks>}
						></Route>
					</Routes>
				</BrowserRouter>
			</div>
		</>
	);
}

export default App;
