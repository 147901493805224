import {
	Box,
	Button,
	Checkbox,
	FormControl,
	InputLabel,
	Menu,
	MenuItem,
	Popover,
	Select,
	Typography,
} from "@mui/material";
import React from "react";
import { headingBlue, maroon, pureWhite } from "../assets/colors";

import {
	fetchLeadsTableData,
	getAllSupervisors,
	getInchargeForLeaders,
	getTLForSupervisor,
} from "../backend/backendApis";
import HttpService from "../backend/requestApis";

import { buttonStyle } from "./formStyle";
import MultiSelectDropDown from "./MultiSelectDropDown";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { leadResponse, leadStatus } from "../utils/constants";

const CustomMenu = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [supervisorsList, setSupervisorsList] = React.useState([]);
	const [leadersList, setLeadersList] = React.useState([]);
	const [inchargeList, setInchargeList] = React.useState([]);
	const [selectedSupervisor, setSelectedSupervisor] = React.useState([]);
	const [selectedLeads, setSelectedLeads] = React.useState([]);
	const [selectedIncharges, setSelectedIncharges] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [designation, setDesignation] = React.useState("");
	const [userContact, setUserContact] = React.useState("");
	const [leadStatusCheckbox, setLeadStatusCheckbox] = React.useState({
		closed: true,
	});
	const [leadResponseCheckbox, setLeadResponseCheckbox] = React.useState({});

	const navigate = useNavigate();

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	React.useEffect(() => {
		async function getDesignationAndContact() {
			let designation = await localStorage.getItem("designation");
			let contact = await localStorage.getItem("contact");
			// let designation = "admin";

			// let designation = "supervisor";
			// let contact = "9536749786";

			// let designation = "incharge";
			// let contact = "6395855080";

			setDesignation(designation);
			setUserContact(contact);
		}

		getDesignationAndContact();
	}, []);

	React.useEffect(() => {
		if (supervisorsList.length === 0) return;
		if (designation === "supervisor") {
			const loggedInSupervisor = supervisorsList
				.filter((supervisor) => supervisor?.contactNumber === userContact)
				.map((supervisor) => supervisor.name);
			console.log(loggedInSupervisor);
			setSelectedSupervisor(loggedInSupervisor);
		}
	}, [supervisorsList]);

	//Fetch supervisors list
	React.useEffect(() => {
		try {
			let options = {
				url: getAllSupervisors,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};
			HttpService(options)
				.then((data) => {
					setSupervisorsList(data?.data?.data);
					console.log("data: ", data);
				})
				.catch((err) => {
					console.log("Error in fetching supervisors list: ", err);
					if (err?.status === 403) {
						localStorage.clear();
						navigate("/Login");
					}
				});
		} catch (err) {
			console.log("Error in fetching supervisors list", err);
		}
	}, []);

	//Fetch leaders list
	React.useEffect(() => {
		if (selectedSupervisor.length === 0) return;
		const selectedSupervisorsData = supervisorsList.filter((supervisor) =>
			selectedSupervisor.includes(supervisor.name)
		);

		const supervisorContactNumbers = selectedSupervisorsData.map(
			(supervisor) => supervisor.contactNumber
		);

		try {
			let options = {
				url: getTLForSupervisor,
				method: "GET",
				params: {
					supervisorContacts: supervisorContactNumbers,
				},
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};
			HttpService(options)
				.then((data) => {
					setLeadersList(data?.data?.data);
					console.log("data: ", data);
				})
				.catch((err) => {
					console.log("Error in fetching leaders list: ", err);
					if (err?.status === 403) {
						localStorage.clear();
						navigate("/Login");
					}
				});
		} catch (err) {
			console.log("Error in fetching leaders list", err);
		}
	}, [selectedSupervisor]);

	//Fetch incharges list
	React.useEffect(() => {
		let leaderContactNumbers;
		if (designation === "incharge") {
			leaderContactNumbers = [userContact];
		} else {
			if (selectedLeads.length === 0) {
				setInchargeList([]);
				return;
			}

			const selectedLeadsData = leadersList?.filter((leader) =>
				selectedLeads?.includes(leader?.leaderName)
			);

			leaderContactNumbers = selectedLeadsData.map((leader) => leader?._id);
		}

		try {
			let options = {
				url: getInchargeForLeaders,
				method: "GET",
				params: {
					leaderContacts: leaderContactNumbers,
				},
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};
			HttpService(options)
				.then((data) => {
					setInchargeList(data?.data?.data);
					console.log("data: ", data);
				})
				.catch((err) => {
					console.log("Error in fetching incharge list: ", err);
				});
		} catch (err) {
			console.log("Error in fetching incharge list", err);
		}
	}, [selectedLeads, designation]);

	var typographyStyle = {
		fontSize: "1rem",
		color: maroon,
		padding: "0.1rem",
	};

	const responseMap = {
		antiAllama: 1,
		timeWaste: 2,
		notInterested: 3,
		lowResponse: 4,
		interestedInKnowledge: 5,
		potentialWORKer: 6,
		vipContact: 7,
	};

	function handleApplyFilter() {
		props.setIsTileFilter(false);
		props.setIsCustomMenuFilter(true);
		props.setIsApplyFilterButtonClicked(true);
		handleClose();
		let selectedHierarchyFilterKey;
		selectedHierarchyFilterKey =
			selectedIncharges.length > 0
				? "inchargeFilter"
				: selectedLeads.length > 0
				? "leaderFilter"
				: selectedSupervisor.length > 0
				? "supervisorFilter"
				: "";

		let selectedHeirarchyFilterContact;
		selectedHeirarchyFilterContact =
			selectedIncharges.length > 0
				? inchargeList
						.filter((incharge) => selectedIncharges.includes(incharge.name))
						.map((incharge) => incharge.contactNumber)
				: selectedLeads.length > 0
				? leadersList
						.filter((leader) => selectedLeads.includes(leader.leaderName))
						.map((leader) => leader._id)
				: selectedSupervisor.length > 0
				? supervisorsList
						.filter((supervisor) =>
							selectedSupervisor.includes(supervisor.name)
						)
						.map((supervisor) => supervisor.contactNumber)
				: null;

		if (designation === "incharge") {
			if (selectedIncharges.length === 0) {
				selectedHierarchyFilterKey = "leaderFilter";
				selectedHeirarchyFilterContact = userContact;
			} else {
				selectedHeirarchyFilterContact = inchargeList
					.filter((incharge) => selectedIncharges.includes(incharge.name))
					.map((incharge) => incharge.contactNumber);
			}
		}

		const statusFilterArray = Object.entries(leadStatusCheckbox)
			.filter(([key, value]) => value === true)
			.map(([key, value]) => key);

		let leadResponseFilterArray = [];

		Object.entries(leadResponseCheckbox).forEach(([key, value]) => {
			if (value === true && responseMap[key]) {
				leadResponseFilterArray.push(responseMap[key]);
			}
		});

		props.setSelectedHierarchyFilterKey(selectedHierarchyFilterKey);

		props.setSelectedHeirarchyFilterContact(selectedHeirarchyFilterContact);
		props.setLeadStatusCheckbox(leadStatusCheckbox);
		props.setLeadResponseFilterArray(leadResponseFilterArray);
		props.setStatusFilterArray(statusFilterArray);
	}

	function handleSelectedSupervisor(supervisor) {
		setSelectedSupervisor(supervisor);
		setSelectedLeads([]);
		setSelectedIncharges([]);
	}

	function handleSelectedLead(lead) {
		setSelectedLeads(lead);
		setSelectedIncharges([]);
	}

	function handleSelectedIncharge(incharge) {
		setSelectedIncharges(incharge);
	}

	const handleLeadStatusCheckbox = (statusName) => {
		setLeadStatusCheckbox((prevState) => {
			const newState = {};
			Object.keys(prevState).forEach((key) => {
				newState[key] = false;
			});
			newState[statusName] = true;
			return newState;
		});
		if (statusName !== "closed") {
			setLeadResponseCheckbox({});
		}
	};

	const handleLeadResponseCheckbox = (responseName) => {
		setLeadResponseCheckbox((prevState) => ({
			...prevState,
			[responseName]: !leadResponseCheckbox[responseName],
		}));
	};

	function handleReset() {
		if (designation !== "supervisor") {
			setSelectedSupervisor([]);
			setLeadersList([]);
		}
		setSelectedLeads([]);
		setSelectedIncharges([]);
		setLeadResponseCheckbox({});
		setLeadStatusCheckbox({});
	}
	console.log(designation);

	if (isLoading) return <Loader />;

	return (
		<div style={{ padding: "0.2rem 0.1rem 0.2rem 1.5rem" }}>
			<Button
				variant="contained"
				aria-describedby="filter-button"
				// aria-controls={open ? 'basic-menu' : undefined}
				// aria-haspopup="true"
				// aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{
					backgroundColor: pureWhite,
					color: headingBlue,
					border: `1px solid ${headingBlue}`,
					padding: "0.2rem 0.5rem 0.2rem 0.5rem",
				}}
			>
				Filters
			</Button>
			<Popover
				id="filter-button"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: "320px",
						maxHeight: "90vh",
						overflowY: "auto",
						scrollbarWidth: "none",
						marginTop: "0.2rem",
					}}
				>
					<Box p={18} sx={{ padding: "1rem 1rem 0rem 1rem" }}>
						<Typography style={typographyStyle}>Lead Status</Typography>
						<Box>
							{leadStatus.map((status) => (
								<LeadCheckbox
									key={status.id}
									label={status.title}
									isCheckboxChecked={leadStatusCheckbox[status.value]}
									setIsCheckboxChecked={() =>
										handleLeadStatusCheckbox(status.value)
									}
								/>
							))}
						</Box>
					</Box>
					<Box p={18} sx={{ padding: "1rem" }}>
						<Typography style={typographyStyle}>Lead Response</Typography>
						<Box>
							{leadResponse.map((leadResponse) => (
								<LeadCheckbox
									key={leadResponse.id}
									label={leadResponse.title}
									isCheckboxChecked={leadResponseCheckbox[leadResponse.value]}
									setIsCheckboxChecked={() =>
										handleLeadResponseCheckbox(leadResponse.value)
									}
									disabled={!leadStatusCheckbox?.closed}
								/>
							))}
						</Box>
					</Box>
					<Box
						style={{
							padding: "1rem",
							display: "flex",
							flexDirection: "column",
							gap: "0.8rem",
						}}
					>
						{designation !== "incharge" && (
							<>
								<MultiSelectDropDown
									label="Select Supervisors"
									optionName={selectedSupervisor}
									setOptionName={handleSelectedSupervisor}
									list={supervisorsList}
									disabled={designation === "supervisor"}
								/>
								<MultiSelectDropDown
									label="Select Leaders"
									optionName={selectedLeads}
									setOptionName={handleSelectedLead}
									list={leadersList}
								/>
							</>
						)}

						<MultiSelectDropDown
							label="Select Incharges"
							optionName={selectedIncharges}
							setOptionName={handleSelectedIncharge}
							list={inchargeList}
						/>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "end",
							gap: "0.5rem",
							backgroundColor: pureWhite,
							borderTop: "1px solid #ddd",
							position: "sticky",
							marginRight: "1rem",
							bottom: 0,
						}}
					>
						<Button
							variant="outlined"
							style={{
								fontSize: "0.7rem",
								padding: "0.3rem 0.6rem 0.3rem 0.6rem",
								color: headingBlue,
								backgroundColor: pureWhite,
								border: "1px solid",
								borderRadius: "0.4rem",
								marginTop: "0.6rem",
								marginBottom: "0.9rem",
							}}
							onClick={handleReset}
						>
							Reset
						</Button>
						<Button
							style={{
								...buttonStyle,
								fontSize: "0.7rem",
								padding: "0.4rem 0.6rem 0.4rem 0.6rem",
							}}
							onClick={handleApplyFilter}
						>
							Apply
						</Button>
					</Box>
				</Box>
			</Popover>
		</div>
	);
};

const LeadCheckbox = ({
	label,
	isCheckboxChecked,
	setIsCheckboxChecked,
	disabled,
}) => {
	return (
		<Box style={{ display: "flex", alignItems: "center" }}>
			<Checkbox
				checked={isCheckboxChecked || false}
				style={{ padding: "0rem 0.1rem 0rem 0.1rem" }}
				onChange={() => setIsCheckboxChecked()}
				disabled={disabled}
			/>
			<Typography sx={{ fontSize: "0.9rem", color: headingBlue }}>
				{label}
			</Typography>
		</Box>
	);
};

export default CustomMenu;
