import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { headingBlue } from "../assets/colors";
import {
	fetchMonthlyTaskDetails,
	monthlyTaskApi,
} from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import { monthlyTasks } from "../data/taskCollections";
import { commonErrorStyleText } from "../utils/commonCSS";
import { getCurrentMonth, monthDropDown } from "../utils/constants";
import BackArrow from "./BackArrow";
import ConfirmationWindow from "./ConfirmationWindow";
import CustomCheckbox from "./CustomCheckbox";
import CustomDropDown from "./CustomDropDown";
import {
	buttonStyle,
	containerStyle,
	errorStyleText,
	imgContainer,
	imgStyle,
} from "./formStyle";
import HeaderBar from "./HeaderBar";
import InfoButton from "./InfoButton";
import Loader from "./Loader";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import WORKslogans from "./WORKslogans";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

const NewMonthlyTaskReportForm = () => {
	// const [checkboxStates, setCheckboxStates] = useState(
	// 	dailyTasks.reduce((acc, task) => ({ ...acc, [task.taskName]: false }), {})
	// );

	const navigate = useNavigate();

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const [contactNumber, setContactNumber] = useState("");
	const [gender, setGender] = useState(null);

	const [checkboxObject, setChecboxObject] = useState({});
	const [checkboxStates, setCheckboxStates] = useState({});

	const [isButtonSubmit, setIsButtonSubmit] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState({});
	const [apiError, setApiError] = useState(null);

	const [selectedDistrict, setSelectedDistrict] = React.useState([]);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedMonthNumber, setSelectedMonthNumber] = useState(0);
	const isMobile = useMediaQuery("(max-width: 600px)");

	const [searchParams] = useSearchParams();

	useEffect(() => {
		async function fetchUser() {
			try {
				setIsLoading(true);
				let contact = await localStorage.getItem("contact");
				if (contact) {
					setContactNumber(contact);
					let monthDetails = getCurrentMonth();
					setSelectedMonth(monthDetails.displayName);
					setSelectedMonthNumber(monthDetails.monthNumber);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					localStorage.clear();
					navigate("/signUp");
				}
			} catch (err) {
				setIsLoading(false);
				localStorage.clear();
				navigate("/signUp");
			}
		}
		setApiError(null);
		fetchUser();
	}, []);

	useEffect(() => {
		async function fetchFormData() {
			if (contactNumber) {
				try {
					setIsLoading(true);
					let token = await localStorage.getItem("token");
					let options = {
						url: fetchMonthlyTaskDetails,
						method: "GET",
						params: {
							contactNumber: contactNumber,
							monthNumber: selectedMonthNumber,
						},
						headers: {
							Authorization: `Bearer ${token}`,
						},
					};
					HttpService(options)
						.then((res) => {
							console.log(res);
							setChecboxObject(res?.data?.data);
							setGender(res?.data?.data?.gender);
							setSelectedDistrict([
								res?.data?.data?.selectedDistrict,
								res?.data?.data?.secondSelectedDistrict,
							]);
							setIsLoading(false);
						})
						.catch((err) => {
							console.log("Error in fetching monthly tasks.", err);
							if (err.status === 409) {
								setApiError(err.response.data.description);
							} else if (err?.status === 403) {
								localStorage.clear();
								setIsLoading(false);
								// setLoadingTableData(false)
								navigate("/signUp");
							} else {
								setApiError(
									"Error in fetching your task status. Please contact Support."
								);
							}
							setIsLoading(false);
						});
				} catch (err) {
					if (err?.status === 403) {
						localStorage.clear();
						setIsLoading(false);
						navigate("/signUp");
					} else {
						setApiError(
							"Error in fetching your task status. Please contact Support."
						);
						setIsLoading(false);
					}
				}
			}
			// } else {
			// 	localStorage.clear();
			// 	setIsLoading(false);
			// 	navigate("/signUp");
			// }
		}
		fetchFormData();
	}, [contactNumber, selectedMonth]);

	useEffect(() => {
		if (Object.keys(checkboxObject).length > 0) {
			setIsLoading(true);
			setCheckboxStates(() =>
				monthlyTasks
					.filter((task) => !task.sectionHeading)
					.reduce(
						(acc, task) => ({
							...acc,
							[task.taskName]: checkboxObject[task.taskName] || false,
						}),
						{}
					)
			);
			setIsLoading(false);
		}
	}, [checkboxObject]);

	const getDistricts = () => {
		if (selectedDistrict.length === 2) {
			return {
				firstDistrict: checkboxObject.selectedDistrict,
				secondDistrict: checkboxObject.secondSelectedDistrict,
			};
		} else if (selectedDistrict.length === 1) {
			return {
				firstDistrict: checkboxObject.selectedDistrict,
			};
		} else {
			return null;
		}
	};

	const districts = getDistricts();

	function updateMonth(option) {
		setSelectedMonth(option.displayName);
		setSelectedMonthNumber(option.monthNumber);
	}

	function closeWindow() {
		setIsOpenWindow(false);
		setIsButtonSubmit(false);
	}

	const handleCheckboxChange = (taskName) => {
		setCheckboxStates((prevState) => ({
			...prevState,
			[taskName]: !prevState[taskName],
		}));
	};

	// Function to get records of checked checkboxes
	const getCheckedTasks = () => {
		return Object.keys(checkboxStates).filter((taskName) => {
			if (Object.keys(districts).length === 2) {
				return checkboxStates[taskName] === true;
			} else {
				return checkboxStates[taskName] === true && !taskName.endsWith("D2");
			}
		});
	};

	const getTasksCount =
		selectedDistrict.length === 2
			? monthlyTasks.filter((task) => task?.taskName).length
			: monthlyTasks.filter(
					(task) => task?.taskName && !task.taskName.endsWith("D2")
			  ).length;

	const isAllTasksCompleted = getCheckedTasks().length === getTasksCount;

	async function handleSubmit() {
		try {
			setApiError(null);
			setIsOpenWindow(true);
			setIsButtonSubmit(true);

			if (getCheckedTasks().length === 0) return;

			let token = await localStorage.getItem("token");
			if (token) {
				checkboxStates.contactNumber = contactNumber;
				checkboxStates.monthNumber = selectedMonthNumber;

				// console.log(checkboxStates);
				let apiOptions = {
					url: monthlyTaskApi,
					method: "POST",
					payload: checkboxStates,
					headers: {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				};
				setOptions(apiOptions);
			} else {
				setIsLoading(false);
				navigate("/signUp");
			}
		} catch (err) {
			console.log("Error: ", err);
			setApiError(null);
		}
	}

	if (isLoading) return <Loader />;

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
				}}
			>
				<HeaderBar isReportForm={true} />

				<div style={containerStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<WORKslogans />
					</div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<BackArrow />
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0rem 0.2rem",
						}}
					>
						<h3>Monthly Task</h3>
						{!isFormSubmitted && (
							<CustomDropDown
								width={isMobile ? "12rem" : "14rem"}
								regular={true}
								list={monthDropDown}
								value={selectedMonth}
								setFunc={updateMonth}
								isValueRequired={true}
							></CustomDropDown>
						)}
					</div>
					<Paper
						style={{
							maxHeight: "50vh",
							overflowY: "auto",
							padding: "0rem",
							scrollbarWidth: "none",
						}}
					>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.2rem",
								padding: "1rem 0.5rem",
							}}
						>
							{isFormSubmitted ? (
								<ReportFormSubmitMessage />
							) : !apiError ? (
								<div>
									{selectedDistrict.length === 0 ? (
										<>
											<Typography
												style={{
													...commonErrorStyleText,
													fontSize: "1.1rem",
													fontWeight: 500,
													textAlign: "center",
												}}
											>
												Choose atleast one District First.
											</Typography>
										</>
									) : (
										monthlyTasks.map((task) => (
											<>
												{task.sectionHeading ? (
													<Typography
														style={{
															marginLeft: "0.5rem",
															fontWeight: "bold",
															color: headingBlue,
															marginTop:
																task.taskTitle === "Offline Tasks" && "0.8rem",
														}}
														key={task.taskId}
													>
														{task.taskTitle}
													</Typography>
												) : (
													<CustomCheckbox
														taskId={task.taskId}
														key={task.taskId}
														label={task.taskTitle}
														description={task.taskDescription}
														isCheckboxChecked={
															checkboxStates[task.taskName || false]
														}
														setIsCheckboxChecked={() =>
															handleCheckboxChange(task.taskName)
														}
														districts={districts}
														taskName={task.taskName}
													/>
												)}
											</>
										))
									)}
									{selectedDistrict.length !== 0 && (
										<div
											style={{
												textAlign: "center",
											}}
										>
											<Button
												style={{ ...buttonStyle, fontSize: "0.8rem" }}
												onClick={handleSubmit}
											>
												Submit
											</Button>
											{isButtonSubmit && (
												<ConfirmationWindow
													windowStatus={isOpenWindow}
													updateWindowStatus={closeWindow}
													isAllTasksCompleted={isAllTasksCompleted}
													options={options}
													isNoTaskChecked={getCheckedTasks().length === 0}
													setIsFormSubmitted={setIsFormSubmitted}
													setIsLoading={setIsLoading}
													setApiError={(err) => {
														setApiError(err);
													}}
												/>
											)}
										</div>
									)}
								</div>
							) : (
								<>
									<Box
										style={{
											display: "flex",
											justifyContent: "center",
											textAlign: "center",
											padding: "0.5rem",
											minHeight: "10vh",
										}}
									>
										<Typography
											style={{
												...errorStyleText,
												justifyContent: "center",
												textAlign: "center",
												fontWeight: "550",
												fontSize: "1rem",
											}}
										>
											{apiError}
										</Typography>
									</Box>
								</>
							)}
						</Container>
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default NewMonthlyTaskReportForm;
