import React, { useCallback } from 'react'
import HeaderBar from './HeaderBar'
import ActionBar from './actionBar'
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import TopCard from './TopCard'
import { containerStyle } from './ReportDashBoardStyle'
import LeadsTable from './LeadsTable'
import { fetchLeadsTableData, getLeadsTiles, getUnAssignedLeads } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { useNavigate } from 'react-router-dom'
import _ from "lodash";
import { bgRed, green, headingBlue, hMapBlue, maroon, orange, red, yellowish } from '../assets/colors'
import { BorderBottom } from '@mui/icons-material'
import LeadsTopBar from './LeadsTopBar'
import UnAssignedLeadsTable from './UnAssignedLeadsTable'
import { leadResponseMapping } from '../utils/constants'

const LeadsDashboard = () => {
	const navigate = useNavigate();

	const [page, setPage] = React.useState(1);
	const [offset, setOffset] = React.useState(5);
	const [sortBy, setSortBy] = React.useState("WORKer");
	const [sortOrder, setSortOrder] = React.useState(1);
	const [filterOn, setFilterOn] = React.useState("Filter On");
	const [filterBy, setFilterBy] = React.useState("");
	const [selectedDashBoard, setSelectedDashboard] = React.useState();
	const [refreshTableApi, setRefreshTableApis] = React.useState(true);
	const [tableData, setTableData] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isTopCardLoading, setIsTopCardLoading] = React.useState(false);
	const [tilesData, setTilesData] = React.useState([]);
	const [selectedTile, setSelectedTile] = React.useState("followUp");
    const [toggle, setToggle] = React.useState('assignedLeads')
    const [unAssignedLeadsData, setUnAssignedLeadsData] = React.useState([])
	const [selectedHierarchyFilterKey, setSelectedHierarchyFilterKey] =
		React.useState("");
	const [selectedHeirarchyFilterContact, setSelectedHeirarchyFilterContact] =
		React.useState([]);
	const [statusFilterArray, setStatusFilterArray] = React.useState([]);
	const [leadResponseFilterArray, setLeadResponseFilterArray] = React.useState(
		[]
	);
	const [leadStatusCheckbox, setLeadStatusCheckbox] = React.useState({});
	const [isCustomMenuFilter, setIsCustomMenuFilter] = React.useState(false);
	const [isTileFilter, setIsTileFilter] = React.useState(true);
	const [isApplyFilterButtonClicked, setIsApplyFilterButtonClicked] =
		React.useState(false);
	const [designation, setDesignation] = React.useState("");
	const [userContact, setUserContact] = React.useState("");

	React.useEffect(() => {
		async function getDesignationAndContact() {
			let designation = await localStorage.getItem("designation");
			let contact = await localStorage.getItem("contact");
			// let designation = "admin";

			// let designation = "supervisor";
			// let contact = "9536749786";

			// let designation = "leader";
			// let contact = "6395855080";

			if (designation === "supervisor") {
				setSelectedHierarchyFilterKey("supervisorFilter");
				setSelectedHeirarchyFilterContact(contact);
			}
			if (designation === "leader") {
				setSelectedHierarchyFilterKey("leaderFilter");
				setSelectedHeirarchyFilterContact(contact);
			}

			setDesignation(designation);
			setUserContact(contact);
		}

		getDesignationAndContact();
	}, []);

	const updateSelectedDashboard = (dashboard) => {
		setPage(1);
		setOffset(10);
		setFilterOn("Filter On");
		setFilterBy("");
		setSelectedDashboard(dashboard);
	};

    const updateToggle = (toggle) => {
        setPage(1)
        setOffset(10)
        setFilterOn('Filter On')
        setFilterBy('')
        if( toggle === 'assignedLeads'){
            setOffset(5)
        }
        setToggle(toggle)
    }

	const updatePage = (page) => {
		setPage(page);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateOffset = (offset) => {
		setPage(1);
		setOffset(offset);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateSort = (sortBy, sortOrder) => {
		setPage(1);
		setFilterOn("Filter On");
		setFilterBy("");
		setSortBy(sortBy);
		setSortOrder(sortOrder);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateFilterOn = (filterOn) => {
		setFilterOn(filterOn);
	};

	const handleSelectedTile = (tileValue) => {
		if (designation === "supervisor") {
			setSelectedHierarchyFilterKey("supervisorFilter");
			setSelectedHeirarchyFilterContact(userContact);
		}
		if (designation === "leader") {
			setSelectedHierarchyFilterKey("leaderFilter");
			setSelectedHeirarchyFilterContact(userContact);
		}
		if (designation === "admin") {
			setSelectedHierarchyFilterKey("");
		}
		setPage(1);
		setSelectedTile(() => tileValue);
		setIsTileFilter(true);
		setIsCustomMenuFilter(false);
	};

	const updateFilterBy = (filterByValue) => {
		setFilterBy(filterByValue);
		debouncedApiCall(filterByValue);
	};

	const debouncedApiCall = useCallback(
		_.debounce((filterByValue) => {
			setPage(1);
			setRefreshTableApis((prev) => !prev);
		}, 500),
		[]
	);

	const handleDownloadAllRecords = () => {
		return new Promise((resolve, reject) => {
			let options = {
				url: fetchLeadsTableData,
				method: "GET",
				params: {
					// leadStatusFilter: { $in: [selectedTile] },
					...(selectedHierarchyFilterKey && {
						[selectedHierarchyFilterKey]: selectedHeirarchyFilterContact,
					}),
					...(isTileFilter && { leadStatusFilter: { $in: [selectedTile] } }),
					...(isCustomMenuFilter && {
						leadStatusFilter: { $in: statusFilterArray },
					}),
					...(isCustomMenuFilter &&
						leadStatusCheckbox.closed && {
							leadResponseFilter: { $in: leadResponseFilterArray },
						}),
				},
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};

			// if (selectedTile === "notInitiated") {
			// 	options["params"]["leadStatusFilter"] = {
			// 		$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
			// 	};
			// }

			// if (selectedTile === "all") {
			// 	options["params"]["leadStatusFilter"] = "all";
			// }

			if (isCustomMenuFilter && leadStatusCheckbox?.notInitiated) {
				options["params"]["leadStatusFilter"] = {
					$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
				};
			}
			if (isCustomMenuFilter && statusFilterArray.length === 0) {
				options["params"]["leadStatusFilter"] = "all";
			}
			if (isTileFilter && selectedTile === "notInitiated") {
				options["params"]["leadStatusFilter"] = {
					$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
				};
			}

			if (isTileFilter && selectedTile === "all") {
				options["params"]["leadStatusFilter"] = "all";
			}

			HttpService(options)
				.then((data) => {
					let result = data?.data?.data?.data
					result.forEach(row => {
						let statusValue = leadResponseMapping[row['leadResponse']]
						row['leadResponse'] = statusValue
					}); 
					resolve(result);
				})
				.catch((err) => {
					if (err?.status === 403) {
						localStorage.clear();
						reject();
						// setLoadingTableData(false)
						navigate("/Login");
					} else {
						// setLoadingTableData(false)
						reject();
					}
				});
		});
	};

	React.useEffect(() => {
		if (designation === "supervisor" || designation === "leader") {
			if (!selectedHierarchyFilterKey) return;
		}
		try {
            if(toggle === 'assignedLeads'){
			setIsLoading(true);
			let options = {
				url: fetchLeadsTableData,
				method: "GET",
				params: {
					sortBy: sortBy,
					sortOrder: sortOrder,
					page: page,
					offset: offset,
					...(selectedHierarchyFilterKey && {
						[selectedHierarchyFilterKey]: selectedHeirarchyFilterContact,
					}),
					...(isTileFilter && { leadStatusFilter: { $in: [selectedTile] } }),
					...(isCustomMenuFilter && {
						leadStatusFilter: { $in: statusFilterArray },
					}),
					...(isCustomMenuFilter &&
						leadStatusCheckbox.closed && {
							leadResponseFilter: { $in: leadResponseFilterArray },
						}),
				},
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};

			if (isCustomMenuFilter && leadStatusCheckbox?.notInitiated) {
				options["params"]["leadStatusFilter"] = {
					$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
				};
			}
			if (isCustomMenuFilter && statusFilterArray.length === 0) {
				options["params"]["leadStatusFilter"] = "all";
			}
			if (isTileFilter && selectedTile === "notInitiated") {
				options["params"]["leadStatusFilter"] = {
					$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
				};
			}

			if (isTileFilter && selectedTile === "all") {
				options["params"]["leadStatusFilter"] = "all";
			}

			if (filterOn != "Filter On") {
				let filterOnValue = "";
				switch (filterOn) {
					case "Lead Name":
						filterOnValue = "name";
						break;
					case "Lead Contact":
						filterOnValue = "contactNumber";
						break;
					case "Phone Name":
						filterOnValue = "phoneName";
						break;
					case "District":
						filterOnValue = "district";
						break;
					case "Chapter":
						filterOnValue = "chapter";
						break;
					default:
						filterOnValue = null;
				}
				options.params.filterOn = filterOnValue;
				options.params.filterBy = filterBy;
			}
			setIsApplyFilterButtonClicked(false);

			HttpService(options)
				.then((data) => {
					setTableData(data?.data?.data);
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					if (err?.status === 403) {
						localStorage.clear();
						// setLoadingTableData(false)
						navigate("/Login");
					} else {
						// setLoadingTableData(false)
					}
				});
            }
		} catch (err) {
			setIsLoading(false);
			console.log("Error in fetching Table Data: ", err);
		}
	}, [
		selectedDashBoard,
		refreshTableApi,
		selectedTile,
		isCustomMenuFilter,
		isTileFilter,
		isApplyFilterButtonClicked,
		selectedHierarchyFilterKey,
	]);

	// React.useEffect(() => {
	// 	try {
	// 		setIsLoading(true);
	// 		let options = {
	// 			url: fetchLeadsTableData,
	// 			method: "GET",
	// 			params: {
	// 				sortBy: sortBy,
	// 				sortOrder: sortOrder,
	// 				page: page,
	// 				offset: offset,
	// 				leadStatusFilter: { $in: [selectedTile] },
	// 			},
	// 			headers: {
	// 				Authorization: localStorage.getItem("token")
	// 					? `Bearer ${localStorage.getItem("token")}`
	// 					: null,
	// 			},
	// 		};

	// 		if (selectedTile === "notInitiated") {
	// 			options["params"]["leadStatusFilter"] = {
	// 				$nin: ["followUp", "notReachable", "closed", "wrongNumber"],
	// 			};
	// 		}

	// 		if (selectedTile === "all") {
	// 			options["params"]["leadStatusFilter"] = "all";
	// 		}

	// 		if (filterOn != "Filter On") {
	// 			let filterOnValue = "";
	// 			switch (filterOn) {
	// 				case "Lead Name":
	// 					filterOnValue = "name";
	// 					break;
	// 				case "Lead Contact":
	// 					filterOnValue = "contactNumber";
	// 					break;
	// 				case "Phone Name":
	// 					filterOnValue = "phoneName";
	// 					break;
	// 				case "District":
	// 					filterOnValue = "district";
	// 					break;
	// 				case "Chapter":
	// 					filterOnValue = "chapter";
	// 					break;
	// 				default:
	// 					filterOnValue = null;
	// 			}
	// 			options.params.filterOn = filterOnValue;
	// 			options.params.filterBy = filterBy;
	// 		}
	// 		HttpService(options)
	// 			.then((data) => {
	// 				setTableData(data?.data?.data);
	// 				setIsLoading(false);
	// 			})
	// 			.catch((err) => {
	// 				setIsLoading(false);
	// 				if (err?.status === 403) {
	// 					localStorage.clear();
	// 					// setLoadingTableData(false)
	// 					navigate("/Login");
	// 				} else {
	// 					// setLoadingTableData(false)
	// 				}
	// 			});
	// 	} catch (err) {
	// 		setIsLoading(false);
	// 		console.log("Error in fetching Table Data: ", err);
	// 	}
	// }, [selectedDashBoard, refreshTableApi, selectedTile]);

    React.useEffect(() => {
        try{
            if(toggle === 'assignedLeads'){
                setIsTopCardLoading(true)
                let options = {
                    url: getLeadsTiles,
                    method: 'GET',
                    headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null 
                    }
                }
                HttpService(options).then(data => {
                    setTilesData(data?.data?.data)
                    setIsTopCardLoading(false)
                }).catch(err => {
                    setIsTopCardLoading(false)
                    if(err?.status === 403){
                        localStorage.clear()
                        // setLoadingTableData(false)
                        navigate('/Login')
                    } else {
                        // setLoadingTableData(false)
                    }
                })
            }
        } catch(err){
            setIsTopCardLoading(false)
            console.log("Error in fetching leads Tiles Data: ", err)
        }
    }, [selectedDashBoard, toggle])

    React.useEffect(() => {
        try{
            if(toggle === 'unAssignedLeads'){
                setIsLoading(true)
                let options = {
                    url: getUnAssignedLeads,
                    method: 'GET',
                    params:{
                        sortBy: sortBy,
                        sortOrder: sortOrder,
                        page: page,
                        offset: offset,
                    },
                    headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null 
                    }
                }

                if( filterOn != 'Filter On'){
                    let filterOnValue = ''
                    switch(filterOn){
                        case 'Lead Name':
                            filterOnValue = 'name'
                            break;
                        case 'Lead Contact':
                            filterOnValue = 'contactNumber'
                            break;
                        case 'Phone Name':
                            filterOnValue = 'phoneName'
                            break;
                        case 'Gender':
                            filterOnValue = 'gender'
                            break;
                        case 'Age':
                            filterOnValue = 'age'
                            break;
                        case 'District':
                            filterOnValue = 'district'
                            break;
                        case 'Chapter':
                            filterOnValue = 'state'
                            break;
                        default:
                            filterOnValue = null
                    }
                    options.params.filterOn = filterOnValue
                    options.params.filterBy = filterBy
                }
                HttpService(options).then(data =>{
                    setUnAssignedLeadsData(data?.data?.data)
                    setIsLoading(false)
                }).catch(err =>{
                    setIsLoading(false)
                    if(err?.status === 403){
                        localStorage.clear()
                        // setLoadingTableData(false)
                        navigate('/Login')
                    } else {
                        // setLoadingTableData(false)
                    }
                })
            }

        } catch(err){
            setIsTopCardLoading(false)
            console.log("Error in fetching leads Tiles Data: ", err)
        }
    },[toggle, refreshTableApi])

	return (
		<>
			<ActionBar
				dashBoard={ toggle === 'assignedLeads' ? "leads": "unAssignedLeads"}
				tableData={tableData}
				setTableData={setTableData}
				setSelectedTile={setSelectedTile}
				setIsTileFilter={setIsTileFilter}
				setIsCustomMenuFilter={setIsCustomMenuFilter}
				setSelectedHierarchyFilterKey={setSelectedHierarchyFilterKey}
				setSelectedHeirarchyFilterContact={setSelectedHeirarchyFilterContact}
				setLeadStatusCheckbox={setLeadStatusCheckbox}
				setLeadResponseFilterArray={setLeadResponseFilterArray}
				setStatusFilterArray={setStatusFilterArray}
				setIsApplyFilterButtonClicked={setIsApplyFilterButtonClicked}
			></ActionBar>
            { (localStorage.getItem('designation') === 'admin' ||
                localStorage.getItem('designation') === 'supervisor' 
            ) &&
                <LeadsTopBar toggle={toggle} setFunc={updateToggle}></LeadsTopBar>
            }
            <Box style={{padding: ['admin', 'supervisor'].includes(localStorage.getItem('designation'))? "0.3rem 1.5rem 0rem 1.5rem" :"0.5rem 1.5rem 1.5rem 1.5rem"}}>
                {toggle === 'assignedLeads' &&
                <>
				<Grid container spacing={2}>
					<Grid item xs={6} sm={2} onClick={() => handleSelectedTile("all")}>
						<TopCard
							heading="All Leads"
							data={
								(tilesData?.notInitiated ? tilesData?.notInitiated : 0) +
								(tilesData?.followUp ? tilesData?.followUp : 0) +
								(tilesData?.notReachable ? tilesData?.notReachable : 0) +
								(tilesData?.wrongNumber ? tilesData?.wrongNumber : 0) +
								(tilesData?.closed ? tilesData?.closed : 0)
							}
							color={hMapBlue}
							isSelected={selectedTile === "all"}
							isSmall={true}
						></TopCard>
					</Grid>
					<Grid
						item
						xs={6}
						sm={2}
						onClick={() => handleSelectedTile("notInitiated")}
					>
						<TopCard
							heading="Not Initiated"
							data={tilesData?.notInitiated ? tilesData?.notInitiated : 0}
							color={hMapBlue}
							isSelected={selectedTile === "notInitiated"}
							isSmall={true}
						></TopCard>
					</Grid>
					<Grid
						item
						xs={6}
						sm={2}
						onClick={() => handleSelectedTile("followUp")}
					>
						<TopCard
							heading="Follow Up"
							data={tilesData?.followUp ? tilesData?.followUp : 0}
							color={yellowish}
							isSelected={selectedTile === "followUp"}
							isSmall={true}
						></TopCard>
					</Grid>
					<Grid
						item
						xs={6}
						sm={2}
						onClick={() => handleSelectedTile("notReachable")}
					>
						<TopCard
							heading="Not Reachable"
							data={tilesData?.notReachable ? tilesData?.notReachable : 0}
							color={orange}
							isSelected={selectedTile === "notReachable"}
							isSmall={true}
						></TopCard>
					</Grid>
					<Grid
						item
						xs={6}
						sm={2}
						onClick={() => handleSelectedTile("wrongNumber")}
					>
						<TopCard
							heading="Wrong Number"
							data={tilesData?.wrongNumber ? tilesData?.wrongNumber : 0}
							color={maroon}
							isSelected={selectedTile === "wrongNumber"}
							isSmall={true}
						></TopCard>
					</Grid>
					<Grid item xs={6} sm={2} onClick={() => handleSelectedTile("closed")}>
						<TopCard
							heading="Closed"
							data={tilesData?.closed ? tilesData?.closed : 0}
							color={green}
							isSelected={selectedTile === "closed"}
							isSmall={true}
						></TopCard>
					</Grid>
				</Grid>
				<LeadsTable
					dashBoard="leads"
					pageValue={page}
					updatePage={updatePage}
					offsetValue={offset}
					updateOffset={updateOffset}
					updateSort={updateSort}
					data={tableData}
					filterOnValue={filterOn}
					updateFilterOn={updateFilterOn}
					filterByValue={filterBy}
					updateFilterBy={updateFilterBy}
					isLoading={isLoading}
					handleDownloadAllRecords={handleDownloadAllRecords}
				></LeadsTable>
                </>
                }
                {
                    toggle === 'unAssignedLeads' &&
                    <>
                        <UnAssignedLeadsTable 
                            dashBoard='unAssignedLeads' pageValue={page}
                            updatePage={updatePage}
                            offsetValue={offset}
                            updateOffset={updateOffset}
                            updateSort={updateSort}
                            data = {unAssignedLeadsData}
                            filterOnValue={filterOn}
                            filterByValue={filterBy}
                            updateFilterOn={updateFilterOn}
                            updateFilterBy={updateFilterBy}
                            isLoading={isLoading}
                            >

                        </UnAssignedLeadsTable>
                    </>
                }
			</Box>
		</>
	);
};

export default LeadsDashboard;
