import dayjs from "dayjs";
import { green, red, yellowish } from "../assets/colors";

export const tableOffsets = [
	{ name: 5 },
	{ name: 10 },
	{ name: 15 },
	{ name: 20 },
	{ name: 50 },
	{ name: 100 },
];

export const tableOffsets2 = [
	{ name: 10 },
	{ name: 15 },
	{ name: 20 },
	{ name: 50 },
	{ name: 100 },
];

export const tableFilterOption = [
	{ name: "WORKer" },
	{ name: "Gender" },
	{ name: "Contact Number" },
	{ name: "District" },
	{ name: "Chapter" },
	{ name: "Selected District 1" },
	{ name: "Selected Chapter 1" },
	{ name: "Selected District 2" },
	{ name: "Selected Chapter 2" },
	{ name: "Alliance" },
	{ name: "Team Leader" },
	{ name: "Supervisor" },
];

export const reportTableFilterOption = [
	{ name: "WORKer" },
	{ name: "Contact Number" },
	{ name: "Alliance" },
	{ name: "Supervisor" },
	{ name: "Team Leader" },
];

export const leadTableFilterOption = [
	{ name: "Lead Name" },
	{ name: "Lead Contact" },
	{ name: "Phone Name" },
	{ name: "District" },
	{ name: "Chapter" },
];

export const unAssignedLeadTableFilterOption = [
	{ name: "Lead Name" },
	{ name: "Lead Contact" },
	{ name: "Phone Name" },
	{ name: "Gender" },
	{ name: "Age" },
	{ name: "District" },
	{ name: "Chapter" },
];

export const leadsTaskList = [
	{
		taskTitle2: "Introduction of Allama",
		taskName: "introductionOfAllama",
	},
	{
		taskTitle2: "Importance of Dars-e-Quran",
		taskName: "importanceOfDarseQuran",
	},
	{
		taskTitle2: "Quran TPI Course",
		taskName: "QuranTPICourse",
	},
	{
		taskTitle2: "Added to WhatsApp Group",
		taskName: "addedToWhatsApp",
	},
	{
		taskTitle2: "Explained 2026",
		taskName: "explained2026",
	},
];

export const dashboardsList = [
	{ name: "One Time Task" },
	{ name: "Daily Task" },
	{ name: "Weekly Task" },
	{ name: "Monthly Task" },
	{ name: "Members" },
	{ name: "Leads" },
];

export const weekDropDown = [
	{ name: "Week 1", displayName: "Week 1 / (25Nov - 1Dec)", weekNumber: 1 },
	{ name: "Week 2", displayName: "Week 2 / (2Dec - 8Dec)", weekNumber: 2 },
	{ name: "Week 3", displayName: "Week 3 / (9Dec - 15Dec)", weekNumber: 3 },
	{ name: "Week 4", displayName: "Week 4 / (16Dec - 22Dec)", weekNumber: 4 },
	{ name: "Week 5", displayName: "Week 5 / (23Dec - 29Dec)", weekNumber: 5 },
	{ name: "Week 6", displayName: "Week 6 / (30Dec - 5Jan)", weekNumber: 6 },
	{ name: "Week 7", displayName: "Week 7 / (6Jan - 12Jan)", weekNumber: 7 },
	{ name: "Week 8", displayName: "Week 8 / (13Jan - 19Jan)", weekNumber: 8 },
	{ name: "Week 9", displayName: "Week 9 / (20Jan - 26Jan)", weekNumber: 9 },
	{ name: "Week 10", displayName: "Week 10 / (27Jan - 2Feb)", weekNumber: 10 },
	{ name: "Week 11", displayName: "Week 11 / (3Feb - 9Feb)", weekNumber: 11 },
	{ name: "Week 12", displayName: "Week 12 / (10Feb - 16Feb)", weekNumber: 12 },
	{ name: "Week 13", displayName: "Week 13 / (17Feb - 23Feb)", weekNumber: 13 },
	{ name: "Week 14", displayName: "Week 14 / (24Feb - 1Mar)", weekNumber: 14 },
	{ name: "Week 15", displayName: "Week 15 / (2Mar - 8Mar)", weekNumber: 15 },
	{ name: "Week 16", displayName: "Week 16 / (9Mar - 15Mar)", weekNumber: 16 },
	{ name: "Week 17", displayName: "Week 17 / (16Mar - 22Mar)", weekNumber: 17 },
	{ name: "Week 18", displayName: "Week 18 / (23Mar - 29Mar)", weekNumber: 18 },
	{ name: "Week 19", displayName: "Week 19 / (30Mar - 5Apr)", weekNumber: 19 },
	{ name: "Week 20", displayName: "Week 20 / (6Apr - 12Apr)", weekNumber: 20 },
	{ name: "Week 21", displayName: "Week 21 / (13Apr - 19Apr)", weekNumber: 21 },
	{ name: "Week 22", displayName: "Week 22 / (20Apr - 26Apr)", weekNumber: 22 },
	{ name: "Week 23", displayName: "Week 23 / (27Apr - 3May)", weekNumber: 23 },
	{ name: "Week 24", displayName: "Week 24 / (4May - 10May)", weekNumber: 24 },
];

// export function getCurrentWeek() {
// 	let today = dayjs();
// 	if (today < dayjs("2024-12-02T00:00:00")) {
// 		console.log("Week :::::: ", weekDropDown[0]);
// 		return weekDropDown[0];
// 	} else if (today < dayjs("2024-12-09T00:00:00")) {
// 		return weekDropDown[1];
// 	} else if (today < dayjs("2024-12-16T00:00:00")) {
// 		return weekDropDown[2];
// 	} else if (today < dayjs("2024-12-23T00:00:00")) {
// 		return weekDropDown[3];
// 	} else if (today < dayjs("2024-12-30T00:00:00")) {
// 		return weekDropDown[4];
// 	} else if (today < dayjs("2025-01-06T00:00:00")) {
// 		return weekDropDown[5];
// 	} else if (today < dayjs("2025-01-13T00:00:00")) {
// 		return weekDropDown[6];
// 	} else if (today < dayjs("2025-01-20T00:00:00")) {
// 		return weekDropDown[7];
// 	} else if (today < dayjs("2025-01-27T00:00:00")) {
// 		return weekDropDown[8];
// 	} else if (today < dayjs("2025-02-03T00:00:00")) {
// 		return weekDropDown[9];
// 	} else if (today < dayjs("2025-02-10T00:00:00")) {
// 		return weekDropDown[10];
// 	} else if (today < dayjs("2025-02-17T00:00:00")) {
// 		return weekDropDown[11];
// 	} else if (today < dayjs("2025-02-24T00:00:00")) {
// 		return weekDropDown[12];
// 	} else {
// 		return weekDropDown[13];
// 	}
// }

export function getCurrentWeek() {
	let today = dayjs();
	const weekStartDates = [
		"2024-12-02",
		"2024-12-09",
		"2024-12-16",
		"2024-12-23",
		"2024-12-30",
		"2025-01-06",
		"2025-01-13",
		"2025-01-20",
		"2025-01-27",
		"2025-02-03",
		"2025-02-10",
		"2025-02-17",
		"2025-02-24",
		"2025-03-03",
		"2025-03-10",
		"2025-03-17",
		"2025-03-24",
		"2025-03-31",
		"2025-04-07",
		"2025-04-14",
		"2025-04-21",
		"2025-04-28",
		"2025-05-05",
	];

	for (let i = 0; i < weekStartDates.length; i++) {
		if (today < dayjs(`${weekStartDates[i]}T00:00:00`)) {
			return weekDropDown[i];
		}
	}
	return weekDropDown[weekDropDown.length - 1];
}

export const monthDropDown = [
	{
		name: "Month 1",
		displayName: "Month 1 / (25 Nov - 25 Dec)",
		monthNumber: 1,
	},
	{
		name: "Month 2",
		displayName: "Month 2 / (26 Dec - 25 Jan)",
		monthNumber: 2,
	},
	{
		name: "Month 3",
		displayName: "Month 3 / (26 Jan - 25 Feb)",
		monthNumber: 3,
	},
	{
		name: "Month 4",
		displayName: "Month 4 / (26 Feb - 25 Mar)",
		monthNumber: 4,
	},
	{
		name: "Month 5",
		displayName: "Month 5 / (26 Mar - 25 Apr)",
		monthNumber: 5,
	},
	{
		name: "Month 6",
		displayName: "Month 6 / (26 Apr - 25 May)",
		monthNumber: 6,
	},
];

// export function getCurrentMonth() {
// 	let today = dayjs();
// 	if (today < dayjs("2024-12-26T00:00:00")) {
// 		return monthDropDown[0];
// 	} else if (today < dayjs("2025-01-26T00:00:00")) {
// 		return monthDropDown[1];
// 	} else if (today < dayjs("2025-02-26T00:00:00")) {
// 		return monthDropDown[2];
// 	} else {
// 		return monthDropDown[3];
// 	}
// }

export function getCurrentMonth() {
	let today = dayjs();
	const monthStartDates = [
		"2024-12-26",
		"2025-01-26",
		"2025-02-26",
		"2025-03-26",
		"2025-04-26",
	];
	for (let i = 0; i < monthStartDates.length; i++) {
		if (today < dayjs(`${monthStartDates[i]}T00:00:00`)) {
			return monthDropDown[i];
		}
	}
	return monthDropDown[monthDropDown.length - 1];
}

export const dashboardColumnMapping = {
	contactNumber: "Contact Number",
	name: "Name",
	gender: "Gender",
	district: "District",
	chapter: "Chapter",
	selectedDistrict: "Selected District",
	selectedChapter: "Selected State",
	secondSelectedDistrict: "Second Selected District",
	secondSelectedChapter: "Second Selected State",
	allianceName: "Alliance",
	leaderName: "Leader",
	supervisorName: "Supervisor",
};

export const leadsColumnMapping = {
	name: "Name",
	gender: "Gender",
	age: "Age",
	contactNumber: "Contact Number",
	phoneName: "Phone Name",
	district: "District",
	chapter: "Chapter",
	inchargeContact: "Incharge Contact",
	inchargeName: "Incharge Name",
	leaderName: "Team Leader",
	supervisorName: "Supervisor",
	leadStatus: "Lead Status",
	leadResponse: "Lead Response",
};

export const unAssignedLeadsColumnMapping = {
	name: "Name",
	gender: "Gender",
	age: "Age",
	contactNumber: "Contact Number",
	phoneName: "Phone Name",
	district: "District",
	state: "Chapter",
};

export const reportColumnMapping = {
	contactNumber: "Contact Number",
	name: "WORKer",
	allianceName: "Alliance",
	supervisorName: "Supervisor",
	leaderName: "Team Leader",
	taskDone: "Tasks Done",
	taskPending: "Tasks Pending",
};

export const leadRatingLevels = {
	1: { label: "Anti Allama", color: red },
	2: { label: "Time Waste", color: red },
	3: { label: "Not Interested", color: yellowish },
	4: { label: "Low Response", color: yellowish },
	5: { label: "Interested In Knowledge", color: yellowish },
	6: { label: "Potential WORKer", color: green },
	7: { label: "VIP Contact", color: green },
};

export const genderList = [{ name: "Male" }, { name: "Female" }];

export const age = [
	{ name: 15 },
	{ name: 16 },
	{ name: 17 },
	{ name: 18 },
	{ name: 19 },
	{ name: 20 },
	{ name: 21 },
	{ name: 22 },
	{ name: 23 },
	{ name: 24 },
	{ name: 25 },
	{ name: 26 },
	{ name: 27 },
	{ name: 28 },
	{ name: 29 },
	{ name: 30 },
	{ name: 31 },
	{ name: 32 },
	{ name: 32 },
];

// export const slogansData = [
// 	"हमारा पूज्य - एक परमेश्वर",
// 	"हमारी जाति - मानव परिवार",
// 	"हमारा सपना - अखंड भारत",
// 	"हमारा गौरव - हिंदुस्तान",
// 	"हमारा संकल्प - युग परिवर्तन",
// 	"सतयुग जब शुरू होगा, भारत विश्वगुरु होगा।",
// ];

export const slogansData = [
	"हमारा पूज्य - एक परमेश्वर",
	"हमारी जाति - मानव परिवार",
	"हमारा सपना - अखंड भारत",
	"हमारा गौरव - हिंदुस्तान",
	"हमारा संकल्प - युग परिवर्तन",
	"सतयुग जब शुरू होगा, भारत विश्वगुरु होगा।",
	"",
	"Our Lord - One God",
	"Our Caste - One Human Family",
	"Our Dream - One Earth (Akhand Bharat)",
	"Our Pride - Our Nation (Hindustan)",
	"Our Pledge - New Era (Yug Parivartan)",
	"When the Golden Age occurs (Satyuga)",
	"We shall be the Global Mentor (Vishwaguru)",
];

export const leadStatus = [
	{ id: 1, title: "Not Initiated", value: "notInitiated" },
	{ id: 2, title: "Follow Up", value: "followUp" },
	{ id: 3, title: "Wrong Number", value: "wrongNumber" },
	{ id: 4, title: "Not Reachable", value: "notReachable" },
	{ id: 5, title: "Closed", value: "closed" },
];

export const leadResponse = [
	{ id: 1, title: "VIP Contact", value: "vipContact" },
	{ id: 2, title: "Potential WORKer", value: "potentialWORKer" },
	{ id: 3, title: "Interested in Knowledge", value: "interestedInKnowledge" },
	{ id: 4, title: "Low Response", value: "lowResponse" },
	{ id: 5, title: "Not Interested", value: "notInterested" },
	{ id: 6, title: "Time Waste", value: "timeWaste" },
	{ id: 7, title: "Anti Allama", value: "antiAllama" },
];

export const leadResponseMapping = {
	1: "Anti Allama",
	2: "Time Waste",
	3: "Not Interested",
	4: "Low Response",
	5: "Interested In Knowledge",
	6: "Potential WORKer",
	7: "VIP Contact",
};
