import React from 'react'
import { amber, green, headingBlue, lightGrey, maroon, mediumGrey, red } from '../assets/colors'
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CustomDropDown from './CustomDropDown'
import CustomTextField from './CustomTextField'
import { leadTableFilterOption, tableOffsets, tableOffsets2, unAssignedLeadTableFilterOption } from '../utils/constants'
import Loader from './Loader'
import { ExpandLess, ExpandMore, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import DownloadDialogBox from './DownloadDialogBox'

const UnAssignedLeadsTable = (props) => {

  const [dialogStatus, setDialogStatus] = React.useState(false)

  const updateDownloadDialogStatus = (status) => {
    setDialogStatus(status)
}
  var outerBoxStyle= {
      width: '100%',
      height: '65vh',
      marginTop: "0.6rem"
  }
  var paperStyle={
      // width: "100%",
      // height: '100%',
      border: '1px solid #D9D9D9',
      padding: "0.1rem 0.1rem 0.1rem 0.1rem",
  }

  var headerBoxStyle = {
      display: 'flex',
      padding: "0.3rem",
      flexWrap: 'wrap',
    }
  
  var internalBoxStyle= {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '20%',
      flex: '1 1',
      flexDirection: "row",
      flexWrap: 'wrap',
      // border: '1px solid'
      // padding: "0.1rem 2rem"
  }

  var typographyStyle= {
      marginLeft: "0.5rem",
      padding:"0.1rem",
      fontSize: "1rem",
      fontWeight: "600"
  }

  var buttonStyle={
      backgroundColor: headingBlue,
      color: 'white',
      // marginRight: '0.2rem',
      marginLeft: "0.7rem",
      fontFamily: "Helvetica",
      fontWeight: '300',
      fontSize: '0.9rem',
      padding: "0.2rem 1rem 0.2rem 1rem"
    }

  var headerCellStyle = {
    padding: '0.2rem 0.5rem 0.2rem 0.5rem', 
    fontSize: "0.9rem", 
    fontWeight: "400",
    textAlign: 'center'
  }

  var iconStyle = { 
    padding: "0rem 0rem 0rem 0.2rem",
    // marginBottom: '-0.3rem',
    // border: '1px solid'
  }

  return (
    <>
        {/* <Box style={outerBoxStyle}> */}
          <Paper style={paperStyle}>
            <Box style={headerBoxStyle}>
              {/* <Box style={internalBoxStyle}>
                <Typography style={typographyStyle}> Unassigned Leads</Typography>

              </Box> */}
              <Box style={{ display:'flex', flex: "1 1", width: "80%", justifyContent: 'flex-end', padding: "0rem 0.5rem 0rem 0.5rem", flexWrap: 'wrap', gap: '0.5rem'}}>
                  <CustomDropDown key='tableFilters' width="11rem" list={unAssignedLeadTableFilterOption} value={props.filterOnValue} placeHolder='Filter On' setFunc={props.updateFilterOn} regular={true}></CustomDropDown>
                  <CustomTextField placeHolder="Enter Text to Filter" value={props.filterByValue} setFunc={props.updateFilterBy}></CustomTextField>
                  <Button variant='outlined' sx={buttonStyle} style={{ textTransform: 'capitalize' }} 
                  onClick={updateDownloadDialogStatus}
                  >Download</Button>
              </Box>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center', overflow: 'auto'}}>
              <TableContainer component={Paper} style={{width: '98%', height: "55.25vh", border: '1px solid #D9D9D9', marginTop: "0.2rem", "&::-webkit-scrollbar":{
                  display: 'none'
              },
              scrollbarWidth: "none"}}>
                  <Table stickyHeader>
                      <TableHead style={{backgroundColor: mediumGrey}}>
                          <TableRow style={{ padding: '0rem 0rem 0rem 0rem'}}>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem',color: headingBlue}}>
                                      Lead Name
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem',color: headingBlue}} size='small' onClick={() => {
                                      props.updateSort('lead.name', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem',color: headingBlue}} size='small' onClick={() => {
                                      props.updateSort('lead.name', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>

                              </TableCell>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      Lead Contact
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.contactNumber', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.contactNumber', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>

                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      Phone Name 
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.phoneName', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.phoneName', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      Gender
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.gender', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.gender', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      Age
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.age', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('lead.age', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      District
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('district', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('district', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>
                              <TableCell style={{...headerCellStyle, backgroundColor: lightGrey}}>
                                  <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem'}}>
                                      Chapter
                                      <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <IconButton style={{...iconStyle, marginBottom: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('state', 1)
                                      }}><ExpandLess fontSize="small"/></IconButton>
                                      <IconButton style={{...iconStyle, marginTop: '-0.25rem'}} size='small' onClick={() => {
                                      props.updateSort('state', -1)
                                      }}><ExpandMore fontSize="small"/></IconButton>
                                      </Box>
                                  </Box>
                              </TableCell>
                          </TableRow>

                      </TableHead>
                      <TableBody style={{ textAlign: 'center', overflowY: 'auto'}}>
                          {props.isLoading && <Loader></Loader>}
                          {props?.data?.data?.map((row, index) => {
                              return(
                                  <TableRow key={index}>
                                      <TableCell  style={{...headerCellStyle, color: headingBlue, fontSize: '0.85rem'}}>
                                          {row.lead.name ? row.lead.name : '-'}                                          
                                      </TableCell>

                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                          {row.lead.contactNumber ? row.lead.contactNumber : '-'}
                                      </TableCell>

                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                          {row.lead.phoneName ? row.lead.phoneName : '-'}
                                      </TableCell>

                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                          {row.lead.gender ? row.lead.gender : '-'}
                                      </TableCell>
                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                          {row.lead.age ? row.lead.age : '-'}
                                      </TableCell>
                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                          {row.district ? row.district : '-'}
                                      </TableCell>
                                      <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>
                                        {row.state ? row.state : '-'}
                                      </TableCell>
                                  </TableRow>
                              )
                          })}

                      </TableBody>

                  </Table>

              </TableContainer>
          </Box>
          <Box style={{display: 'flex', justifyContent: 'center',padding: "0.4rem 0rem 0.1rem 0rem"}}>
                        <Box style={{width: "30%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: "0rem 0.5rem 0rem 0.9rem"}}>
                            <Typography style={{color: maroon, fontSize: '0.9rem'}}>
                                {((props?.pageValue - 1) * props?.offsetValue) +1} - {(props?.pageValue * props?.offsetValue) < (props?.data?.totalRecords) ? (props?.pageValue * props?.offsetValue) : props?.data?.totalRecords} of {props?.data?.totalRecords}
                            </Typography>
                        </Box>
                        <Box style={{width: '40%',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <IconButton size='small'
                          onClick={() => {
                              if(props.pageValue > 1){
                              props.updatePage(props.pageValue - 1)
                              }
                          }}
                          disabled={props.pageValue === 1}
                              ><KeyboardArrowLeft size='small' 
                              sx= {{color: props.pageValue === 1 ? mediumGrey: headingBlue}}
                              /></IconButton>
                          <Button variant='contained' style={{minWidth: '0rem', width: '1.5rem', height: '1.5rem',borderRadius: '50%', backgroundColor: headingBlue, color: 'white', padding: '0rem', alignItems: 'center'}}>
                              {props.pageValue}
                              </Button>
                              <IconButton size='small'
                              onClick={() => {
                                  if(props.pageValue < props?.data?.totalPages){
                                  props.updatePage(props.pageValue + 1)
                                  }
                              }}
                              disabled={props.pageValue === props?.data?.totalPages}
                              ><KeyboardArrowRight size='small' 
                              sx= {{color: (props.pageValue === props?.data?.totalPages || props?.data?.totalPages === 0) ? mediumGrey: headingBlue}}
                              /></IconButton>

                          </Box>
                          <Box style={{width: '30%',display: 'flex', justifyContent: 'flex-end', padding: "0rem 0.6rem 0rem 0rem"}}>
                              <CustomDropDown key='table' value={props.offsetValue} width="5rem" setFunc={props.updateOffset} list={tableOffsets2} regular={true}></CustomDropDown>
                          </Box>

                      </Box>
          

          </Paper>
          <DownloadDialogBox dialogStatus={dialogStatus} updateDownloadDialogStatus= {updateDownloadDialogStatus} data ={props?.data?.data} dashBoard='unAssignedLeads'></DownloadDialogBox>

        {/* </Box> */}
    </>
  )
}

export default UnAssignedLeadsTable