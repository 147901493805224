import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dailyTasks, dailyTasksWithTwitter } from "../data/taskCollections";
import CustomCheckbox from "./CustomCheckbox";
import {
	buttonStyle,
	containerStyle,
	errorStyleText,
	imgContainer,
	imgStyle,
} from "./formStyle";
import InfoButton from "./InfoButton";
import { dailyTaskApi, fetchDailyTaskDetails } from "../backend/backendApis";
import ConfirmationWindow from "./ConfirmationWindow";
import CustomDatePicker from "./CustomDatePicker";
import dayjs from "dayjs";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import HeaderBar from "./HeaderBar";
import HttpService from "../backend/requestApis";
import Loader from "./Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUser } from "../utils/commonFunctions";
import { commonErrorStyleText } from "../utils/commonCSS";
import BackArrow from "./BackArrow";
import WORKslogans from "./WORKslogans";
import Countdown from "./Countdown";
 
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

const DailyTaskReportForm = () => {
	// const [checkboxStates, setCheckboxStates] = useState(
	// 	dailyTasks.reduce((acc, task) => ({ ...acc, [task.taskName]: false }), {})
	// );

	const navigate = useNavigate();

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const [contactNumber, setContactNumber] = useState("");
	const [gender, setGender] = useState(null);

	const [checkboxObject, setChecboxObject] = useState({});
	const [checkboxStates, setCheckboxStates] = useState({});

	const [isButtonSubmit, setIsButtonSubmit] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState({});
	const [apiError, setApiError] = useState(null);

	const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
	const [assignedDistrict, setAssignedDistrict] = React.useState(0);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		async function fetchUser() {
			try {
				setIsLoading(true);
				let contact = await localStorage.getItem("contact");
				if( contact ){
					setContactNumber(contact);
					const propsDate = searchParams.get('reportDate')
					if ( propsDate){
						setSelectedDate(dayjs(propsDate))
					}
					setIsLoading(false);
				} else {
					setIsLoading(false);
					localStorage.clear()
					navigate("/signUp");
				}
				
			} catch (err) {
				setIsLoading(false);
				localStorage.clear()
				navigate("/signUp");
			}
		}
		setApiError(null);
		fetchUser();
	}, []);

	useEffect(() => {
		async function fetchFormData() {
			if (contactNumber) {
				try {
					setIsLoading(true);
					let token = await localStorage.getItem("token");
					let options = {
						url: fetchDailyTaskDetails,
						method: "GET",
						params: {
							contactNumber: contactNumber,
							reportDate: dayjs(dayjs(dayjs(selectedDate).format()).utc())
								.tz("Asia/Kolkata")
								.format("YYYY-MM-DD"),
						},
						headers: {
							Authorization: `Bearer ${token}`,
						},
					};
					HttpService(options)
						.then((res) => {
							// console.log(res);
							setChecboxObject(res.data.data);
							setGender(res.data.data.gender);
							setAssignedDistrict(res?.data?.data?.assignedDistrict);
							setIsLoading(false);
						})
						.catch((err) => {
							console.log("Error in fetching daily tasks.", err);
							if (err.status === 409) {
								setApiError(err.response.data.description);
							} else if (err?.status === 403) {
								localStorage.clear();
								setIsLoading(false);
								// setLoadingTableData(false)
								navigate("/signUp");
							} else {
								setApiError(
									"Error in fetching your task status. Please contact Support."
								);
							}
							setIsLoading(false);
						});
				} catch (err) {
					if (err?.status === 403) {
						localStorage.clear();
						setIsLoading(false);
						navigate("/signUp");
					} else {
						setApiError(
							"Error in fetching your task status. Please contact Support."
						);
						setIsLoading(false);

					}
				}
			}
			// } else {
			// 	localStorage.clear();
			// 	setIsLoading(false);
			// 	navigate("/signUp");
			// }
		}
		fetchFormData();
	}, [contactNumber, selectedDate]);

	useEffect(() => {
		if (Object.keys(checkboxObject).length > 0) {
			setIsLoading(true);
			setCheckboxStates(() =>
				selectedDate >= dayjs(process.env.REACT_APP_TWITTER_TASK_DATE)
					? dailyTasksWithTwitter.reduce(
							(acc, task) => ({
								...acc,
								[task.taskName]: checkboxObject[task.taskName] || false,
							}),
							{}
					  )
					: dailyTasks.reduce(
							(acc, task) => ({
								...acc,
								[task.taskName]: checkboxObject[task.taskName] || false,
							}),
							{}
					  )
			);
			setIsLoading(false);
		}
	}, [checkboxObject]);

	const getDistricts = () => {
		if (assignedDistrict === 2) {
			return {
				firstDistrict: checkboxObject.selectedDistrict,
				secondDistrict: checkboxObject.secondSelectedDistrict,
			};
		} else {
			return null;
		}
	};

	const districts = getDistricts();

	function closeWindow() {
		setIsOpenWindow(false);
		setIsButtonSubmit(false);
	}

	const handleCheckboxChange = (taskName) => {
		setCheckboxStates((prevState) => ({
			...prevState,
			[taskName]: !prevState[taskName],
		}));
	};

	// Function to get records of checked checkboxes
	const getCheckedTasks = () => {
		return Object.keys(checkboxStates).filter(
			(taskName) => checkboxStates[taskName] === true
		);
	};

	const isAllTasksCompleted =
		selectedDate >= dayjs(process.env.REACT_APP_TWITTER_TASK_DATE)
			? assignedDistrict === 1
				? getCheckedTasks().length === dailyTasksWithTwitter.length - 2
				: getCheckedTasks().length === dailyTasksWithTwitter.length
			: assignedDistrict === 1
			? getCheckedTasks().length === dailyTasks.length - 2
			: getCheckedTasks().length === dailyTasks.length;

	async function handleSubmit() {
		try {
			setApiError(null);
			setIsOpenWindow(true);
			setIsButtonSubmit(true);

			if (getCheckedTasks().length === 0) return;

			let token = await localStorage.getItem("token");
			if (token) {
				checkboxStates.contactNumber = contactNumber;
				checkboxStates.gender = gender;
				checkboxStates.reportDate = dayjs(
					dayjs(dayjs(selectedDate).format()).utc()
				)
					.tz("Asia/Kolkata")
					.format("YYYY-MM-DD");
				checkboxStates.districtsAssigned = assignedDistrict;
				// console.log(checkboxStates);
				let apiOptions = {
					url: dailyTaskApi,
					method: "POST",
					payload: checkboxStates,
					headers: {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				};
				setOptions(apiOptions);
			} else {
				setIsLoading(false);
				navigate("/signUp");
			}
		} catch (err) {
			console.log("Error: ", err);
			setApiError(null);
		}
	}

	if (isLoading) return <Loader />;

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
				}}
			>
				<HeaderBar isReportForm={true} />

				<div style={containerStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<WORKslogans />
					</div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<BackArrow />
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0rem 0.2rem",
						}}
					>
						<h3>Daily Task</h3>
						{!isFormSubmitted && (
							<CustomDatePicker
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
							/>
						)}
					</div>
					<Paper
						style={{
							maxHeight: "50vh",
							overflowY: "auto",
							padding: "0rem",
							scrollbarWidth: "none",
						}}
					>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.2rem",
								padding: "1rem 0.5rem",
							}}
						>
							{isFormSubmitted ? (
								<ReportFormSubmitMessage />
							) : !apiError ? (
								<div>
									{selectedDate >
									dayjs(process.env.REACT_APP_TWITTER_TASK_DATE) ? (
										assignedDistrict === 0 ? (
											<>
												<Typography
													style={{
														...commonErrorStyleText,
														fontSize: "1.1rem",
														fontWeight: 500,
														textAlign: "center",
													}}
												>
													Choose atleast one District First.
												</Typography>
											</>
										) : (
											dailyTasksWithTwitter.map((task) => (
												<CustomCheckbox
													taskId={task.taskId}
													key={task.taskId}
													label={task.taskTitle}
													description={task.taskDescription}
													isCheckboxChecked={
														checkboxStates[task.taskName || false]
													}
													setIsCheckboxChecked={() =>
														handleCheckboxChange(task.taskName)
													}
													districts={districts}
													taskName={task.taskName}
												/>
											))
										)
									) : (
										dailyTasks.map((task) => (
											<CustomCheckbox
												taskId={task.taskId}
												key={task.taskId}
												label={task.taskTitle}
												description={task.taskDescription}
												isCheckboxChecked={
													checkboxStates[task.taskName || false]
												}
												setIsCheckboxChecked={() =>
													handleCheckboxChange(task.taskName)
												}
												districts={districts}
												taskName={task.taskName}
											/>
										))
									)}
									{assignedDistrict !== 0 && (
										<div
											style={{
												textAlign: "center",
											}}
										>
											<Button
												style={{ ...buttonStyle, fontSize: "0.8rem" }}
												onClick={handleSubmit}
											>
												Submit
											</Button>
											{isButtonSubmit && (
												<ConfirmationWindow
													windowStatus={isOpenWindow}
													updateWindowStatus={closeWindow}
													isAllTasksCompleted={isAllTasksCompleted}
													options={options}
													isNoTaskChecked={getCheckedTasks().length === 0}
													isDailyTask="true"
													setIsFormSubmitted={setIsFormSubmitted}
													setIsLoading={setIsLoading}
													setApiError={(err) => {
														setApiError(err);
													}}
												/>
											)}
										</div>
									)}
								</div>
							) : (
								<>
									<Box
										style={{
											display: "flex",
											justifyContent: "center",
											textAlign: "center",
											padding: "0.5rem",
											minHeight: "10vh",
										}}
									>
										<Typography
											style={{
												...errorStyleText,
												justifyContent: "center",
												textAlign: "center",
												fontWeight: "550",
												fontSize: "1rem",
											}}
										>
											{apiError}
										</Typography>
									</Box>
								</>
							)}
						</Container>
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default DailyTaskReportForm;
