import { useEffect, useState } from "react";
import { mediumGrey, red } from "../assets/colors";

const AnalogClock = () => {
	const [glow, setGlow] = useState(false);

	useEffect(() => {
		const updateClock = () => {
			const now = new Date();
			const sec = now.getSeconds();
			const min = now.getMinutes();
			const hr = now.getHours() % 12;

			const secAngle = sec * 6;
			const minAngle = min * 6 + sec * 0.1;
			const hrAngle = hr * 30 + min * 0.5;

			document
				.getElementById("second")
				.setAttribute("transform", `rotate(${secAngle} 25 25)`);
			document
				.getElementById("minute")
				.setAttribute("transform", `rotate(${minAngle} 25 25)`);
			document
				.getElementById("hour")
				.setAttribute("transform", `rotate(${hrAngle} 25 25)`);

			// Trigger glow effect
			setGlow(true);
			setTimeout(() => setGlow(false), 700);
		};

		const interval = setInterval(updateClock, 1000);
		updateClock(); // Initialize clock on load

		return () => clearInterval(interval); // Cleanup on unmount
	}, []);

	return (
		<svg
			width="50"
			height="50"
			viewBox="-2 -2 55 55"
			xmlns="http://www.w3.org/2000/svg"
		>
			{/* Glowing Effect Circle */}
			<circle
				cx="25"
				cy="25"
				r="24"
				fill="none"
				stroke="black"
				strokeWidth="2"
				opacity="0"
				className={glow ? "glow-circle" : ""}
			/>

			{/* Clock Face */}
			<circle
				cx="25"
				cy="25"
				r="23.5"
				fill={mediumGrey}
				stroke="black"
				strokeWidth="1.25"
			/>

			{/* Clock Center */}
			<circle cx="25" cy="25" r="1.25" fill="black" />

			{/* Centered Text (2026) */}
			<text
				x="25"
				y="35"
				textAnchor="middle"
				fontSize="12"
				fontWeight="bold"
				fill="red"
			>
				2026
			</text>

			{/* Hour Hand */}
			<line
				id="hour"
				x1="25"
				y1="25"
				x2="25"
				y2="15"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
			/>

			{/* Minute Hand */}
			<line
				id="minute"
				x1="25"
				y1="25"
				x2="25"
				y2="10"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>

			{/* Second Hand */}
			<line
				id="second"
				x1="25"
				y1="25"
				x2="25"
				y2="5"
				stroke="black"
				strokeWidth="1"
				strokeLinecap="round"
			/>

			<style>
				{`
                .glow-circle {
                    animation: glowEffect 0.7s ease-in-out;
                }

               @keyframes glowEffect {
                  0% { opacity: 0.6; stroke-width: 2; filter: blur(0.5px); }
                  50% { opacity: 0.4; stroke-width: 3; filter: blur(1.5px); }
                  100% { opacity: 0; stroke-width: 4; filter: blur(3px); }
                }
				`}
			</style>
		</svg>
	);
};

export default AnalogClock;
