import React, { useState, useEffect } from "react";

const AnimatedNumbers = ({ value = 0, isDaysCircle }) => {
	const [prevValue, setPrevValue] = useState(() =>
		isDaysCircle ? value + 2 : value + 1
	);
	const [animateIndexes, setAnimateIndexes] = useState([]);

	useEffect(() => {
		if (prevValue > value) {
			const interval = setInterval(() => {
				const nextValue = prevValue - 1;
				const prevDigits = prevValue
					.toString()
					.padStart(value.toString().length, "0")
					.split("");

				const nextDigits = nextValue
					.toString()
					.padStart(value.toString().length, "0")
					.split("");

				// Find which digits are actually decreasing
				const newAnimateIndexes = prevDigits.map(
					(digit, i) => parseInt(digit) > parseInt(nextDigits[i])
				);

				setAnimateIndexes(newAnimateIndexes);

				setTimeout(() => {
					setPrevValue(nextValue);
					setAnimateIndexes([]);
				}, 400);
			}, 500);

			return () => clearInterval(interval);
		}
	}, [value, prevValue]);

	return (
		<div style={{ display: "flex", overflow: "hidden", fontSize: "0.8rem" }}>
			{prevValue
				.toString()
				.padStart(value.toString().length, "0")
				.split("")
				.map((digit, index) => {
					const nextValue = prevValue - 1;
					const nextDigit = nextValue
						.toString()
						.padStart(value.toString().length, "0")[index];

					const shouldAnimate = animateIndexes[index]; // Only animate decreasing digits

					return (
						<div
							key={index}
							style={{
								position: "relative",
								width: "0.5em",
								height: "1.2rem",
								overflow: "hidden",
							}}
						>
							{/* Current Digit (Moves Down) */}
							<div
								style={{
									position: "absolute",
									top: shouldAnimate ? "100%" : "0",
									transition: "top 0.5s ease-in-out",
								}}
							>
								{digit}
							</div>

							{/* Next Digit (Scrolls In From Above) */}
							{shouldAnimate && (
								<div
									style={{
										position: "absolute",
										top: shouldAnimate ? "200%" : "100%",
										transition: "top 0.5s ease-in-out",
									}}
								>
									{nextDigit}
								</div>
							)}
						</div>
					);
				})}
		</div>
	);
};

export default AnimatedNumbers;
