import React from 'react'
import { containerStyle, imgContainer, imgStyle } from './formStyle'
import WORKslogans from './WORKslogans'
import HeaderBar from './HeaderBar'
import BackArrow from './BackArrow'
import { Box, Paper, Tooltip, Typography } from '@mui/material'
import { amber, green, headingBlue, lightGrey, maroon, mediumGrey, orange, pureWhite, textColor, yellowish } from '../assets/colors'
import { lightGreen } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'
import { fetchMissingDailyTasks } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { SummarizeOutlined } from "@mui/icons-material";
import dayjs from 'dayjs'
import { dailyTasks, dailyTasksWithTwitter } from '../data/taskCollections'
import Loader from './Loader'

const MissingDailyTasks = (props) => {
  const [missingTasks, setMissingTasks] = React.useState([])
  const [totalMissingTasks, setTotalMissingTasks] = React.useState(0)
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDialog, setIsDialog] = React.useState(props.isDialog ? props.isDialog : false)

  const navigate = useNavigate()
  
  React.useEffect(() =>{
    try{
        setIsLoading(true)
        let token = localStorage.getItem('token')
        if(token){
            let options = {
                url: fetchMissingDailyTasks,
                method: "GET",
                params: {
                    contactNumber: !isDialog ? localStorage.getItem("contact") : props.selectedMember,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            HttpService(options).then((res) => {
                setMissingTasks(res?.data?.data?.data)
                setTotalMissingTasks(res?.data?.data?.totalMissingTasks)
                setIsLoading(false)
            }).catch((err) => {
                console.log("Error in fetching missing tasks: ", err)
                setIsLoading(false)
                if (err?.status === 403) {
                    localStorage.clear();
                    // setIsLoading(false);
                    navigate("/signUp");
                }
            })

        } else {
            navigate('/signUp')
        }

    } catch(err){
        console.log('Error in fetching missing Tasks:', err)
    }
  }, [])

  return (
    <>
        {!isDialog &&
            <HeaderBar isReportForm={true}></HeaderBar>
        }
        <div style={ !isDialog ?  {...containerStyle, marginTop: '1.2rem'}: {}}>
            {!isDialog && 
                <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // padding: '0.3rem 0rem 0rem 0rem'
                    }}>
                        <WORKslogans></WORKslogans>
                </div>
            }
            <div style={imgContainer}>
                {!isDialog && 
                <>
                    <img 
                        src={require("../assets/images/WORK_logo.png")}
                        style={imgStyle}
                    >
                    </img>
                    <BackArrow></BackArrow>
                    </>
                }
                <Paper style={{marginTop: '0.2rem', padding:'0.1rem'}}
                >
                    <Box style={{display:'flex', flexDirection: 'row', padding: '0.5rem 1rem 0.5rem 1rem'}}>
                        <Box style={{display: 'flex', justifyContent: 'flext-start', color: maroon, width:'49%'}}>
                            <Typography style={{textAlign: 'left', color: headingBlue, fontSize: "1rem", fontWeight: 600}}>
                                Pending Daily Tasks
                            </Typography>
                        </Box>
                        <Box style={{display: 'flex', justifyContent: 'flex-end', color: maroon, width: '49%'}}>
                            <Typography style={{color: maroon, fontSize: "1rem", fontWeight: 600, textAlign: 'right'}}>
                                Total - {totalMissingTasks}
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box 
                        style={{
                            overflow: 'auto'
                        }}
                        sx={{
                            "&::-webkit-scrollbar":{
                            display: 'none'
                            },
                            scrollbarWidth: "none",
                            maxHeight: isMobile ? '46vh' : '37vh'
                        }}
                    >
                    {isLoading ? <Loader></Loader>:
                        (
                            missingTasks.length > 0 && totalMissingTasks > 0 ? (
                                missingTasks?.map((missingTask, index) =>(
                                    <Row task= {missingTask} index={index} isDialog={isDialog}></Row>
                                ))

                            ) : (
                                <>
                                <Box style={{marginTop: '2rem', padding: '0.5rem', fontSize: '1.2rem', color: maroon}}>
                                    <Typography style={{fontWeight: 600}}>Alhamdulillah No Pending Tasks</Typography>
                                </Box>
                                </>
                            )
                        )
                    }
                    </Box>
                </Paper>
                
            </div>
        </div>
    </>
  )
}

export default MissingDailyTasks

function Row({task, index, isDialog}){
    const navigate = useNavigate()

    const [toolTipOpen, setToolTipOpen] = React.useState(false)
    return(
        <>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: !isDialog ? "1fr 2fr 1.5fr 1.5fr": "1.5fr 3fr 1.5fr",
                    gap: "0.7rem",
                    border: "1px solid",
                    borderColor: mediumGrey,
                    borderRadius: "0.2rem",
                    padding: "0.2rem 0.4rem",
                    cursor: "pointer",
                    backgroundColor: pureWhite,
                    alignItems: "center",
                    marginTop: '0.1rem'
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = lightGrey)}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = pureWhite)}
                // onClick={handleClick}
            >
                <Typography>
                    {index+1}
                </Typography>
                <Typography>
                    {task?.reportDate ? dayjs(task.reportDate.split('T')[0]).format('DD/MM/YYYY'): ''}

                </Typography>
                <Tooltip 
                    componentsProps={{
                        tooltip:{
                            sx:{
                                backgroundColor: maroon,
                                color: 'white',
                                padding: '0.2rem 0.5rem 0.2rem 0.5rem',
                                maxWidth: '15rem'
                            }
                        }
                    }}
                    // sx={{ 
                    //     "& .MuiTooltip-tooltip": 
                    //         { backgroundColor: "#800000", 
                    //             color: "white" 
                    //         } 
                    //     }}
                    open={toolTipOpen} 
                    onTouchStart={() => setToolTipOpen(true)}
                    // onClick={() => setToolTipOpen(true)}
                    onMouseEnter={() => setToolTipOpen(true)} // For desktop
                    onMouseLeave={() => setToolTipOpen(false)} // For desktop
                    onTouchEnd={() => setTimeout(() => setToolTipOpen(false), 2000)}
                    title={
                    <>
                    <Typography style={{fontSize: '0.9rem', fontWeight: 600}}>
                        {task?.reportDate ? dayjs(task.reportDate.split('T')[0]).format('DD/MM/YYYY'): ''}
                    </Typography>
                    {
                        task.missingTasks.map((ele, index) => {
                            let taskConfig = dailyTasksWithTwitter.find((task) => task.taskName === ele)
                            if( taskConfig?.dd === 0){
                                return <Typography style={{ fontSize: '0.8rem'}}>{`${index+1}) ${taskConfig.taskTitle2}`}</Typography>
                            } else if( taskConfig?.dd === 1 ){
                                return <Typography style={{fontSize: '0.8rem'}}>{`${index+1}) ${taskConfig.taskTitle2.split('dd')[0]} ${task.result.selectedDistrict} ${taskConfig.taskTitle2.split('dd')[1]}`} </Typography>
                            }
                            else if( taskConfig?.dd === 2 ){
                                return <Typography style={{fontSize: '0.8rem'}}>{`${index+1}) ${taskConfig.taskTitle2.split('dd2')[0]} ${task.result.secondSelectedDistrict} ${taskConfig.taskTitle2.split('dd2')[1]}`} </Typography>
                            }
                            // <Typography>{ele}</Typography>
                        })
                    }
                    </>
                    }>
                    <Typography style={{color: textColor}}>
                        {task?.missingTasks?.length}
                    </Typography>
                </Tooltip>
                {!isDialog && 
                    <Box style={{display:'flex', flexDirection:'row'}}>
                        <Box onClick = { () =>{
                            navigate(`/content-sheet?reportDate=${task?.reportDate}`)
                        }}>
                            <DescriptionOutlinedIcon style={{color: headingBlue}}></DescriptionOutlinedIcon>
                        </Box>
                        <Box onClick = { () =>{
                            navigate(`/daily-task?reportDate=${task?.reportDate}`)
                        }}>
                            <SummarizeOutlined style={{color: headingBlue}}></SummarizeOutlined>
                        </Box>
                    </Box>
                }
                

            </Box>
        </>
    )
}